<template>
  <!-- Add Employee Modal -->
  <div id="user_profile" class="modal custom-modal d-block" role="dialog" ref='modalWindowRef'
    @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content px-3">
        <div class="modal-header">
          <h5 class="modal-title">Информация профиля</h5>
          <button type="button" class="btn-close" @click='() => onClose(false)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- profile-img-wrap edit-img -->
          <div class='profile-img-wrap edit-img profile__avatar'>
            <div class='avatar__photo-container' data-bs-toggle="dropdown" aria-expanded="false">
              <img class="rounded-circle" :src="serverData.image_path || photoPlaceholder" alt="User Image" />
              <span class='avatar__photo-tooltip'>Редактировать</span>
            </div>
            <div class="dropdown dropdown-action avatar__dropdown">
              <div class="dropdown-menu dropdown-menu-right avatar__dropdown-menu">
                <a class="dropdown-item" @click='setShowAvatarSettings(true)'>
                  Сменить</a>
                <a class="dropdown-item" @click='deleteAvatar'>Удалить</a>
              </div>
            </div>
          </div>
          <avatar-editor v-if='isShowAvatarSettings' :onClose='setShowAvatarSettings' />

          <core-form url='/me/user' :auth='true' v-slot='{ errors, isLoading }' :additionalData="additionalData"
            :initialValidation='schema' :onSuccess='onSuccess'>
            <div class="row">
              <div class="input-block mb-3">
                <core-text-input v-if='role' :isIgnore='true' disabled :showLabel='true' label='Роль' name='role'
                  :data='data' placeholder='Роль' />
              </div>
            </div>
            <div class="row">
              <div class="input-block mb-3">
                <core-text-input required :showLabel='true' label='Фамилия Имя Отчество' name='name' :errors='errors'
                  :data='data' :stringOptions='stringOptions' placeholder='Введите ваше ФИО' mask='A A A'
                  :showError='true' :disabled='isInitialLoading' />
              </div>
            </div>
            <div v-if='!editMode.email' class="row">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Электронная почта
                </label>
                <div class='position-relative'>
                  <core-email-input :isIgnore='true' :disabled='true' label='Электронная почта' :data='serverData'
                    name='email' />
                  <div class='profile__icon-container' :disabled='isInitialLoading' @click='setEditMode("email")'>
                    <vue-feather type="edit-3" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if='editMode.email' class="row">
              <div class="input-block mb-3">
                <core-email-input required :showLabel='true' label='Укажите новый email' :data='data' :errors='errors'
                  name='email' :showError='true' />
              </div>
            </div>

            <div v-if='editMode.email' class="row">
              <div class="input-block mb-4 position-relative">
                <core-email-input required :showLabel='true' label='Повторите email' :data='data' :errors='errors'
                  name='email_confirmation' :showError='true' />
                <div class='profile__cancel-edit' @click='setEditMode("email")'>Отмена</div>
              </div>
            </div>

            <div class="row">
              <div class="input-block mb-3">
                <core-phone-input label='Мобильный номер телефона' :showLabel='true' name='phone' :data='data'
                  :errors='errors' placeholder='+7 (912) 555 22 22' maskedValueFieldName='maskedUserPhone'
                  :showError='true' :disabled='isInitialLoading' />
              </div>
            </div>

            <div v-if='!editMode.password' class="row">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Пароль
                </label>
                <div class='position-relative'>
                  <core-password-input :isIgnore='true' disabled label='Пароль' name='password' type="password"
                    :data='serverData' placeholder='12345' />
                  <div class='profile__icon-container' :disabled='isInitialLoading' @click='setEditMode("password")'>
                    <vue-feather type="edit-3" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if='editMode.password' class="row">
              <div class="input-block mb-3 position-relative">
                <router-link class='forgot-password-tooltip text-secondary' to='/forgot-password'>Забыли
                  пароль?</router-link>
                <core-password-input required :showLabel='true' label='Текущий пароль' name='old_password'
                  type="password" :data='data' :errors='errors' placeholder='Введите текущий пароль'
                  :showError='true' />
              </div>
            </div>

            <div v-if='editMode.password' class="row">
              <div class="input-block mb-3">
                <core-password-input required :showLabel='true' label='Новый пароль ' name='password' type="password"
                  :data='data' :errors='errors' placeholder='Введите новый пароль' :showError='true' />
              </div>
            </div>

            <div v-if='editMode.password' class="row">
              <div class="input-block mb-3 position-relative">
                <core-password-input required :showLabel='true' label='Подтвердите пароль' name='password_confirmation'
                  type="password_confirmation" :data='data' :errors='errors' placeholder='Подтвердите пароль'
                  :showError='true' />
                <div class='profile__cancel-edit' @click='setEditMode("password")'>Отмена</div>
              </div>
            </div>


            <div class='buttons_container'>
              <core-submit-button className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading'
                :disabled='isInitialLoading' />
              <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show"></div>
</template>
<script setup>
import { reactive, onMounted, ref } from 'vue';
import request from '@/shared/core-api/index.ts';
import photoPlaceholder from '@/assets/img/profiles/user-avatar.png';
import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";
import { computed } from 'vue';
import { stringOptions } from "@/shared/validation/index.ts";
import { useUserStore } from '@/stores/user.ts';
import { USER_ROLE_ENUM } from '@/shared/validation/enums.ts';
const modalWindowRef = ref();
const isInitialLoading = ref(false);
const user = useUserStore();

const role = computed(() => {
  return user.role !== USER_ROLE_ENUM.RESOURSE_MANAGER
})
const schema = computed(() => {
  let initialSchema = {
    name: FieldsValidation.requiredString,
    phone: FieldsValidation.phoneNumberNotReq,
  }

  if (editMode.email) {
    const emailSchema = {
      email: FieldsValidation.email,
      email_confirmation: FieldsValidation.email,
    }

    initialSchema = { ...initialSchema, ...emailSchema }
  }

  if (editMode.password) {
    const passwordSchema = {
      old_password: FieldsValidation.password,
      password: FieldsValidation.password,
      password_confirmation: FieldsValidation.password,
    }

    initialSchema = {
      ...initialSchema, ...passwordSchema
    }
  }

  return Yup.object().shape(initialSchema);
})
const isShowAvatarSettings = ref(false);
const setShowAvatarSettings = async (isShow, image) => {
  isShowAvatarSettings.value = isShow;

  if (image) {
    serverData.image_path = image;
  }
}

const deleteAvatar = async () => {
  try {
    await request('/me/user_photo', {
      method: 'DELETE',
      auth: true
    })

    user.avatar = null;
    serverData.image_path = null
  } catch (e) {
  }
}

const getUserData = async () => {
  try {
    const { data: responseData } = await request('/me', {
      auth: true
    })

    return responseData
  } catch (e) {
    isInitialLoading.value = false
  }
}

const props = defineProps({
  onClose: Function
})

const additionalData = {
  _method: 'PATCH'
}

const editMode = reactive({
  email: false,
  password: false,
})

const serverData = reactive({
  password: '******'
})

const data = reactive({
  role: '',
  name: '',
  email: '',
  old_password: '',
  password: '',
  password_confirmation: '',
  email_confirmation: '',
  phone: '',
  maskedUserPhone: ''
})

onMounted(async () => {
  try {
    isInitialLoading.value = true;
    const responseData = await getUserData();
    Object.assign(data, { ...responseData, email: '' });
    data['maskedUserPhone'] = responseData.phone;
    Object.assign(serverData, responseData);
    isInitialLoading.value = false
  } catch (e) {
    isInitialLoading.value = false;
  }
})

const setEditMode = (type) => {
  editMode[type] = !editMode[type]
}

const onSuccess = () => {
  props.onClose(false)
}

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false)
  }
}

</script>
