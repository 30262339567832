<template>
  <div>
    <label v-if='showLabel' class="col-form-label">
      {{ label }}
      <span v-if='required' class="text-danger">*</span>
    </label>
    <div class="position-relative pass-group">
      <Field :standalone='isIgnore' :name="name" class="form-control" v-model='data[name]'
        :validateOnModelUpdate='false' :validateOnChange='false' :validateOnBlur='false' :validateOnInput='false'
        :validateOnMount='false' placeholder=''>
        <input class="form-control pass-input mt-2 password-input_toggled" :type="showPassword ? 'text' : 'password'"
          :class="{ 'is-invalid': showError && errors[name] }" :placeholder='placeholder || "Введите пароль"'
          v-model="data[name]" :disabled='disabled' v-maska:[passwordStringOptions] :data-maska='mask' />
        <span v-if='!isIgnore' @click="toggleShow" class="toggle-password" :class='{
          "toggle-password_error": errors && errors[name]
        }'>
          <i :class="{
            'fas fa-eye': showPassword,
            'fas fa-eye-slash': !showPassword,
          }"></i>
        </span>
      </Field>
      <div class="invalid-feedback">{{ showError && errors[name] }}</div>
      <div class="emailshow text-danger" :id="name"></div>
    </div>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { vMaska } from "maska";
import { onMounted, onBeforeMount, ref } from 'vue';
const props = defineProps({
  data: Object,
  disabled: Boolean,
  placeholder: String,
  showError: Boolean,
  mask: {
    type: String, default: 'AAAAAA'
  },
  errors: Object,
  name: String,
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isIgnore: {
    type: Boolean, default: false
  }
})

const showPassword = ref(false)

const toggleShow = () => {
  showPassword.value = !showPassword.value
}

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

const allSymbolsStr = new RegExp(/(.*)/);
const passwordStringOptions = {
  tokens: {
    A: {
      pattern: allSymbolsStr,
      multiple: false,
    },
  },
}

</script>