import { useRoute } from "vue-router";
import createDebounce from "@/shared/utils/debounce.ts";
import { setParams, addParams } from "@/shared/utils/updateQueryParams.ts";
import { watch, reactive, ref } from "vue";
import { ShowTableModeEnum } from "@/pages/requests/index.ts";

export const useResourceTMRequestsFilters = (
  updateServerOptions,
  isShowTable
) => {
  const route = useRoute();
  const initialParams = route.query;
  const debounce = createDebounce();
  const initialPage = ref(initialParams.page || 1);
  const tableMode = ref(initialParams.mode || ShowTableModeEnum.Requests);

  const filters = reactive({
    search_query: initialParams.search_query || "",
    status: initialParams.status || "",
    work_type: initialParams.work_type || "",
    project_id: initialParams.project_id || "",
  });

  const onChange = () => {
    setParams({ ...filters, mode: tableMode.value });
    updateServerOptions({ ...filters });
  };

  watch(
    filters,
    () => {
      debounce(onChange);
    },
    { deep: true }
  );

  const resetFilters = () => {
    filters.search_query = "";
    filters.status = "";
    filters.work_type = "";
    filters.project_id = "";
  };

  const changeTableMode = (mode) => {
    if (tableMode.value !== mode) {
      isShowTable.value = false;
      setTimeout(() => {
        filters.work_type = "";
        filters.project_id = "";
        filters.status = "";
        initialPage.value = 1;
        tableMode.value = mode;
        isShowTable.value = true;
        addParams({ mode: tableMode.value }, route.query);
      });
    }
  };

  return {
    filters,
    initialPage,
    resetFilters,
    changeTableMode,
    tableMode,
  };
};
