export const FILE_TYPE = {
  IMAGE: "IMAGE",
  PDF: "PDF",
  NOT_SUPPORTED: "NOT_SUPPORTED",
};

export const getFileExt = (name) => {
  const extIndex = name.lastIndexOf(".");
  const ext = name.slice(extIndex + 1);
  return ext;
};

export const getFileType = (name) => {
  const imagesExt = ["jpeg", "jpg", "png"];
  const ext = getFileExt(name);
  if (imagesExt.includes(ext)) {
    return FILE_TYPE.IMAGE;
  } else if (ext === "pdf") {
    return FILE_TYPE.PDF;
  } else {
    return FILE_TYPE.NOT_SUPPORTED;
  }
};

export const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) { 
    bytes = (bytes / 1073741824).toFixed(2) + " GB"; 
  } else if (bytes >= 1048576) { 
    bytes = (bytes / 1048576).toFixed(2) + " MB"; 
  } else if (bytes >= 1024) { 
    bytes = (bytes / 1024).toFixed(2) + " KB"; 
  } else if (bytes > 1) { 
    bytes = bytes + " bytes"; 
  } else if (bytes == 1) { 
    bytes = bytes + " byte"; 
  } else { 
    bytes = "0 bytes"; 
  }
  return bytes;
}