<template>
  <div class="row filter-row table-filter-row">
    <div class="col-sm table-filter-row_tam-row">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>

    <div class="col-sm table-filter-row_tam-row">
      <div class="input-block mb-2 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" :disabled='isDisabledStatus' name='status'
          :options="statusOptions" placeholder="Фильтровать по статусу" />
      </div>
    </div>

    <div class="col-sm table-filter-row_tam-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='project_id' :options="projectOptions"
          placeholder="Фильтровать по проекту" valueProp='id' displayName='title' />
      </div>
    </div>

    <div class="col-sm table-filter-row_tam-row">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input :data='filters' className="filter-select" name='work_type' :options="workTypeOptions"
          placeholder="Фильтровать по Типу" />
      </div>
    </div>

    <div class="col-sm table-filter-row_tam-row">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import request from '@/shared/core-api/index.ts';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { formatStringArrForCoreSelect } from '@/shared/core-form/ui/core-select-input/utils/index.ts';

const props = defineProps({
  mode: Number,
  filters: Object,
  resetFilters: Function,
});

const statusOptions = ref([]);
const projectOptions = ref([]);
const workTypeOptions = ref([]);


const isDisabledStatus = computed(() => {
  return props.mode === 1
})

onMounted(async () => {
  try {
    const { request_statuses, tam_request_work_types } = await getEnums(['request_statuses', 'tam_request_work_types'], {
      auth: true
    })

    statusOptions.value = formatStringArrForCoreSelect(request_statuses);
    workTypeOptions.value = formatStringArrForCoreSelect(tam_request_work_types);

    const { data: responseData } = await request('/projects?archived=0', {
      method: 'GET',
      auth: true,
    });

    projectOptions.value = responseData;
  } catch (e) {

  }
})

</script>
