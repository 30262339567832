export const requestsUrl = "/dashboard/requests";
export const resourceApplicationsUrl = "/dashboard/requests?type=applications";
export const resourceResponsesUrl = "/dashboard/requests?type=responses";
export const resourceTableUrl = "/dashboard/requests-table";

export const meUrl = "/me";
export const countersUrl = "/dashboard/counters";
export const requestsHeaders = [
  {
    text: "ID проекта",
    value: "number",
    sortable: false,
    custom: true,
    width: 114,
  },
  { text: "Название проекта", value: "name", sortable: false, custom: true },
  {
    text: "Запросы на рассмотрении",
    value: "under_review_requests_it",
    sortable: false,
    custom: true,
  },
  {
    text: "Согласованные  запросы",
    value: "agreed_requests_it",
    sortable: false,
    custom: true,
  },
];

export const applicationHeaders = [
  {
    text: "ID проекта",
    value: "number",
    sortable: false,
    custom: true,
    width: 114,
  },
  { text: "Название проекта", value: "name", sortable: false, custom: true },
  {
    text: "Заявок на рассмотрении",
    value: "under_review_requests",
    sortable: false,
    custom: true,
  },
  {
    text: "Откликов на рассмотрении",
    value: "agreed_requests",
    sortable: false,
    custom: true,
  },
];

export const graphicHeader = [
  { text: "", value: "graphic", sortable: false, custom: true },
];
