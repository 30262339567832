import { computed } from "vue";

export function useCompareValues(actualData, metaData) {
  const compareValues = (keysArr) => {
    let isChanged = false;
    if (Object.keys(metaData).length === 0) {
      return false;
    }
    keysArr.forEach((key) => {
      if (actualData[key] !== metaData[key]) {
        if (key.includes("masked")) {
          return;
        }

        isChanged = true;
      }
    });

    return isChanged;
  };

  const isChanged = computed(() => {
    return compareValues(Object.keys(actualData));
  });

  return {
    isChanged,
  };
}
