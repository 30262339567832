import { useUserStore } from "@/stores/user.ts";
import { ref } from "vue";
import { getCookie } from "@/shared/utils/cookie.ts";
import request from "@/shared/core-api/index.ts";


export const useNotifications = () => {
  const counter = ref(0);
  const notifications = ref([]);
  const timer = ref(null);

  const getNotifications = async (delay) => {
    timer.value = setTimeout(async () => {
      try {
        await uploadNotifications();
        getNotifications(60000)
      } catch (e) {
        getNotifications(60000)
      }
    }, delay)
  };

  const uploadNotifications = async() => {
    try {
      const user = useUserStore();
      const token = getCookie("token");
      if (token && !user.isAdminRole) {
        const { data, meta } = await request("/notifications/unread", {
          auth: true,
          queryParamsBody: {
            per_page: 10,
            page: 1,
          },
        });
        
        notifications.value = data;
        counter.value = meta.total;
      }
    } catch (e) {
      throw (e)
    }
  }

  const resetNotifications = () => {
    notifications.value = [];
    counter.value = 0;
  }

  return {
    counter,
    timer,
    notifications,
    getNotifications,
    resetNotifications,
    uploadNotifications
  };
};
