<template>
  <core-form url='/tam_applications' :initialValidation='schema' :additionalData='additionalData'
    v-slot='{ errors, isLoading }' :onSuccess="onSuccess" :auth='true'>
    <div v-for="(item, index) in approvedWorkers" :key="item.id" class='mb-3'>
      <div class='d-flex w-100'>
        <div class='approve-tam-request__executor-cell'>
          <p>Исполнитель</p>
          <p>{{ item.company_name }}</p>
        </div>
        <div class='approve-tam-request__cell'>
          <p>Ставка</p>
          {{ item.pay_rate && Number(item.pay_rate).toLocaleString() }} руб
        </div>
        <div class='approve-tam-request__cell'>
          <p>Дата создания</p>
          <p>{{ fromTimestampToISOString(item.created_at * 1000) }}</p>
        </div>
        <div class='approve-tam-request__checkbox'>
          <div><core-checkbox-input :data='item' name='checked' disabled :isIgnore="true" /></div>
        </div>
      </div>
      <div>
        <core-textarea-input label='Описание услуг' :required='true' :showLabel='true' :data="data"
          :name='`responses[${index}].description`' rows="2" :showError="true" :errors='errors' />
      </div>
    </div>
    <div class='row'>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <core-text-input label='ID заявки' :showLabel='true' :errors='errors' :showError='true' :data='data'
            name='number' required='true' placeholder='XX123456' mask='AA######'
            :stringOptions='agreementNumberOptions' />
        </div>
      </div>
      <div class="col-sm-6 d-flex">
        <div class="input-block mb-3">
          <label class='w-100' style='height: 36.5px;'></label>
          <core-radio-input :data='data' name='with_vat' :options='radioOptions' :initialValue='1' className='d-flex' />
        </div>
      </div>
    </div>
    <div class="buttons_container">
      <core-submit-button className="btn btn-primary submit-btn" :disabled='isDisabled' :isLoading='isLoading'
        text='Далее' />
      <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
    </div>
  </core-form>
</template>
<script setup>
import { reactive, computed, onMounted } from 'vue';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { schema } from "@/shared/validation/approveTamRequest.ts";
import { agreementNumberOptions } from "@/shared/validation/index.ts";
import { useCreateToast } from '../../utils/useCreateToast.ts';

const props = defineProps({
  approvedWorkers: Object,
  id: String,
  onClose: Function,
  requestId: String,
});

const radioOptions = [{
  label: 'С ндс',
  value: 1,
}, {
  label: 'Без ндс',
  value: 0
}];

const data = reactive({
  with_vat: 1,
  number: '',
});

const additionalData = {
  user_id: props.id,
  request_id: props.requestId
}

onMounted(() => {
  props.approvedWorkers.forEach((worker, index) => {
    additionalData[`responses[${index}][id]`] = worker.id;
    data[`responses[${index}].description`] = ''
  });
});

const onSuccess = (values) => {
  useCreateToast(values.number);
  props.onClose(false)
}

const isDisabled = computed(() => {
  let disabled = false;
  Object.entries(data).forEach(([key, value]) => {
    if (!value && value !== 0 && value !== '0') {
      disabled = true;
    }
  });
  return disabled
});

</script>