<template>
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input name="company_name" className="filter-select" :data="filters" :options="companyOptions"
          placeholder="Фильтровать по компании" />
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input name="status" className="filter-select" :data="filters" :options="statusOptions"
          placeholder="Фильтровать по статусу" />
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted } from 'vue'
import request from '@/shared/core-api/index.ts';
const props = defineProps({
  filters: Object,
  resetFilters: Function,
});

const statusOptions = reactive([
  { value: 'Новый', label: 'Новый' },
  { value: 'Отклонено', label: 'Отклонено' }
]);

const companyOptions = reactive([]);

onMounted(async () => {
  try {
    const typeResponse = await request('/admin/register_requests/companies_list', {
      headers: {
        Accept: "application/json",
      },
      auth: true,
    });
    typeResponse && typeResponse.data.forEach(item => {
      companyOptions.push(item)
    })
  } catch (e) {

  }
});
</script>
