export const fpRequestsUrl = "/fp_requests";
export const itManagerHeaders = [
  {
    text: "Исполнитель",
    value: "company_name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  { text: "Стоимость", value: "cost", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "КП", value: "file", sortable: false, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const purchasingManagerHeaders = [
  {
    text: "Исполнитель",
    value: "company_name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  { text: "Стоимость", value: "cost", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "КП", value: "file", sortable: false, custom: true },
];
