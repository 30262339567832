import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const schema = Yup.object().shape({
  company: Yup.object().shape({
    type: FieldsValidation.notEmptyString,
    tin: FieldsValidation.tin,
    okpo: FieldsValidation.okpo,
    okved: FieldsValidation.okved,
    kpp: FieldsValidation.kpp,
    phone: FieldsValidation.phoneNumberNotReq,
    ogrn: FieldsValidation.ogrn,
    short_name: FieldsValidation.notEmptyString,
    addresses: Yup.array().of(
      Yup.object().shape({
        index: FieldsValidation.index,
      })
    ),
    bank_accounts: Yup.array().of(
      Yup.object().shape({
        correspondent_account: FieldsValidation.correspondent_account,
        current_account: FieldsValidation.correspondent_account,
        bik: FieldsValidation.bik,
      })
    ),
    contracts: Yup.array().of(
      Yup.object().shape({
        number: FieldsValidation.agreementNumberNotReq,
      })
    ),
  }),
});
