<template>
  <create-request v-if='isShowRequest.isShow' :onClose='showModal' :id='isShowRequest.id' :mode='currentRequestMode' />
  <request-card-info v-if='isShowInfoCard.isShow' :onClose='showCard' :id='isShowInfoCard.id' />
  <div class="content container-fluid">
    <breadcrumb-header />

    <!-- Search Filter -->
    <tm-requests-filters :mode='filters.archived' :filters='filters' :resetFilters='resetFilters' />
    <!-- /Search Filter -->

    <div class="row">
      <div class="col-md-12">
        <core-table url='/tam_requests' ref='tableRef' :headers='headers' :minHeight='300' :initialPage='initialPage'
          :initialData='initialFiltersState' :isSaveFilters='true'>
          <template v-slot:placeholder>
            <div class="tab-bar">
              <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified core-canvas__tabs">
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs"
                    :class='!filters.archived && filters.archived !== 0 && "active"'
                    href="#solid-rounded-justified-tab1" data-bs-toggle="tab" @click='setArchiveMode(null)'>Все
                    запросы</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" :class='filters.archived === 0 && "active"'
                    href="#solid-rounded-justified-tab2" data-bs-toggle="tab" @click='setArchiveMode(0)'>Активные</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" :class='filters.archived === 1 && "active"'
                    href="#solid-rounded-justified-tab3" data-bs-toggle="tab" @click='setArchiveMode(1)'>В архиве</a>
                </li>
                <li class="nav-item" v-if='user.isITManagerRole'>
                  <a class="nav-link h-100 rounded-tabs" @click='showModal(true)'>+ Создать запрос</a>
                </li>
              </ul>
            </div>
          </template>
          <template #item-status="{ status }">
            <div :class='getStatusClass(status)' class='p-2 text-center'>
              {{ status }}
            </div>
          </template>
          <template #item-project_name="{ project }">
            <div>
              {{ project && project.name }}
            </div>
          </template>
          <template #item-created_at='{ created_at }'>
            <div>
              {{ fromTimestampToISOString(created_at && created_at * 1000) }}
            </div>
          </template>
          <template #item-actions="{ status, id }">
            <div class="text-end">
              <div v-if='!user.isITManagerRole && getStatusPermission(status, [StatusesEnums.DRAFT])'></div>
              <div v-else class="dropdown dropdown-action">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a v-if='getStatusPermission(status, [StatusesEnums.NEW, StatusesEnums.DRAFT, StatusesEnums.CONSIDIRATION]) && user.isITManagerRole'
                    class="dropdown-item" @click='showModal(true, id, ModeEnums.EDIT)'>
                    <span class='action-icon-container'>
                      <i class="fa-solid fa-pencil"></i>
                    </span>
                    Редактировать</a>
                  <a v-if='!getStatusPermission(status, [StatusesEnums.DRAFT])' class="dropdown-item"
                    @click='showCard(true, id)'>
                    <span class='action-icon-container'>
                      <i class="fa fa-external-link"></i>
                    </span>
                    Открыть
                  </a>
                  <a v-if='!getStatusPermission(status, [StatusesEnums.DRAFT]) && user.isITManagerRole'
                    class="dropdown-item" @click='showModal(true, id, ModeEnums.TEMPLATE)'>
                    <span class='action-icon-container'>
                      <img :src='templateIcon' class='template-icon'>
                    </span>
                    Создать по шаблону
                  </a>
                  <a v-if='!getStatusPermission(status, [StatusesEnums.ARCHIVED]) && user.isITManagerRole'
                    class="dropdown-item" @click='archive(id)'>
                    <span class='action-icon-container'>
                      <i class="fa-solid fa-archive"></i>
                    </span>
                    В архив</a>
                </div>
              </div>
            </div>
          </template>
        </core-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useUserStore } from '@/stores/user.ts';
import { ref } from 'vue'
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { getStatusClass, StatusesEnums, getStatusPermission, ModeEnums } from '@/pages/requests/index.ts'
import { headers } from './request-tm.options.ts';
import request from '@/shared/core-api/index.ts';
import { useTMRequestsFilters } from '@/widgets/filters/index.ts';
import templateIcon from '@/assets/img/icons/template.svg';

const user = useUserStore();
const tableRef = ref();
const updateServerOptions = (data, isResetPage = false) => {
  tableRef.value.updateServerOptions(data, isResetPage)
}

const {
  setArchiveMode,
  filters,
  initialFiltersState,
  initialPage,
  resetFilters
} = useTMRequestsFilters(updateServerOptions);


const isShowInfoCard = ref({
  isShow: false,
  id: null
});

const isShowRequest = ref({
  isShow: false,
  id: null,
});

// write or readonly
const currentRequestMode = ref(null);
const showModal = (isShow, id, mode) => {
  isShowRequest.value.isShow = isShow;
  isShowRequest.value.id = id ? id : null;
  currentRequestMode.value = mode ? mode : null

  if (!isShow) {
    tableRef.value.updateServerOptions()
  }
}

const showCard = (isShow, id) => {
  isShowInfoCard.value.isShow = isShow;
  isShowInfoCard.value.id = id ? id : null;

  if (!isShow) {
    tableRef.value.updateServerOptions()
  }
}

const archive = async (id) => {
  tableRef.value.setIsLoading(true);
  await request(`/tam_requests/${id}/in_archive`, {
    method: 'POST',
    auth: true
  });
  tableRef.value.setIsLoading(false);
  updateServerOptions()
}

</script>
<style scoped>
.tab-bar {
  @apply w-max;

}

:deep(.nav-item) {
  flex-basis: auto !important;
}
</style>
