<template>
  <div class="account-page">
    <success-modal :title='modalTitle' :body='modalBody' :button='modalButton' :onSuccess='modalCallback'
      :onHide='modalCallback' />
    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#success-modal" ref='achorRef'></a>
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container register-container">
          <div class="account-logo">
            <router-link to="/"><img src="@/assets/img/logo2.png" alt="Dreams Technologies" /></router-link>
          </div>

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Регистрация</h3>
              <p class="account-subtitle">Доступ к нашей панели управления</p>

              <core-form url="/register_requests" :onSuccess="onSuccess" :initialValidation="schema"
                v-slot="{ errors, isLoading, setErrors }">
                <div class="input-block mb-4">
                  <label class="col-form-label">
                    ФИО<span class="text-danger">*</span>
                  </label>
                  <core-text-input name="user.name" :errors='errors' :data='data' :showError='true'
                    :stringOptions='stringOptions' placeholder='Введите ваше ФИО' mask='A A A' />
                </div>

                <div class="input-block mb-4">
                  <label class="col-form-label">
                    Электронная почта<span class="text-danger">*</span>
                  </label>
                  <core-email-input name='user.email' placeholder="Email" :data='data' :errors='errors'
                    :showError='true' />
                </div>
                <div class="input-block mb-4">
                  <label class="col-form-label">
                    Мобильный номер телефона<span class="text-danger">*</span>
                  </label>
                  <core-phone-input name='user.phone' maskedValueFieldName='maskedPhone'
                    placeholder="+7 (912) 555 22 22" :data='data' :errors='errors' :showError='true' />
                </div>

                <div class="input-block mb-4">
                  <label class="col-form-label">
                    Организационно-правовая форма<span class="text-danger">*</span>
                  </label>
                  <core-select-input name='company.type' placeholder="Введите организационно-правовую форму"
                    :options="regOptions" :data='data' :errors='errors' :showError='true' />
                </div>

                <div class="input-block mb-4">
                  <label class="col-form-label">
                    Сокращенное наименование компании<span class="text-danger">*</span>
                  </label>
                  <core-text-input name='company.short_name' :data='data' :errors='errors' :showError='true'
                    placeholder="Введите сокращенное наименование компании" />
                </div>

                <div class="input-block mb-4">
                  <label class="col-form-label">
                    Загрузите документы<span class="text-danger">*</span>
                    <br />
                    <span class="text-gray-50 text-xs">Поддерживаемый формат: {{ allowedExts.join(', ') }}</span>
                  </label>
                  <core-multiple-files-input :allowedExts='allowedExts' :data='data' name='files' :errors='errors'
                    :showError='true' :setErrors='setErrors' />
                </div>
                <div class="input-block mb-4 text-center">
                  <core-submit-button className='btn btn-primary auth-button' type="submit" text='
                    Отправить запрос на регистрацию' :isLoading='isLoading' />
                </div>
              </core-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import request from '@/shared/core-api/index.ts';
import { stringOptions } from "@/shared/validation/index.ts";
import { schema } from "@/shared/validation/register.ts";
import { router } from "@/router";
const achorRef = ref();
const modalTitle = 'Отличная работа!';
const modalBody = 'Ваш запрос на регистрацию успешно отправлен. Пожалуйста, ожидайте ответа по указанной Вами электронной почте.';
const modalButton = 'Продолжить';
const allowedExts = ['DOC', 'DOCX', 'JPEG', 'PNG', 'XLS', 'CSV', 'PPT', 'TXT', 'RTF', 'PDF', 'TIFF']
let regOptions = reactive([])
onMounted(async () => {
  try {
    const { data } = await request('/enums/get_company_types');
    data && data.forEach(item => {
      regOptions.push(item)
    })
  } catch (e) {

  }
})

const data = reactive({
  'user.name': '',
  'user.email': '',
  'user.phone': '',
  'company.short_name': '',
  'company.type': '',
  'maskedPhone': '',
  files: [],
})

const onSuccess = async (data) => {
  achorRef.value.click()
}

const modalCallback = () => {
  router.push('/')
}

</script>
