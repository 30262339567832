<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">
          {{ handleTitle ? handleTitle : pageTitle }}
        </h3>

      </div>
      <div class="col-auto float-end ms-auto">
        <slot name="additional"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  handleTitle: {
    type: String, default: ''
  }
})

</script>
<script>
export default {
  inject: ['currentRoute'],
  computed: {
    pageTitle() {
      return (this.currentRoute.meta?.parentTitle
        ? this.currentRoute.meta?.parentTitle + ' / '
        : ''
      ) + (this.currentRoute.meta?.title ?? '')
    },
  },
};
</script>
