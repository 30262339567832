<template>
  <div class="pagination" >
    <span>
      Показаны c {{ range.from }} по {{range.to}} из {{total}} записей
    </span>
    <a-pagination
      v-model:current="currentValue"
      v-model:page-size="pageSizeValue"
      :show-size-changer="false"
      :total="total"
      responsive
    >
      <template #itemRender="{ type, originalElement }">
        <template v-if="type === 'prev'">
          <button class="pagination-button">Предыдущий</button>
        </template>
        <template v-else-if="type === 'next'">
          <button class="pagination-button">Следующий</button>
        </template>
        <component :is="originalElement" v-else></component>
      </template>
    </a-pagination>

  </div>
</template>

<script>

export default {
  name: "PaginationComponent",
  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  emits: [
    'update:current',
    'update:page-size',
  ],
  computed: {
    range() {
      return {
        from: (this.current - 1) * this.pageSize + 1,
        to: Math.min(this.current * this.pageSize, this.total)
      }
    },
    currentValue: {

    },
    pageSizeValue: {

    }
  },
}
</script>

<style scoped>
.pagination {
  @apply pt-3 flex flex-row justify-between;
}
.pagination-button {
  @apply px-3 rounded-none text-gray-500 h-full flex flex-row items-center ;
}
</style>
