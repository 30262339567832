<template>
  <!-- Add Employee Modal -->
  <div class="modal custom-modal fade show d-block" ref='modalWindowRef' @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg" @click='localOnClose'>
      <div class="modal-content">
        <div class="modal-header">
          <Tooltip :title="file.name" placement="leftBottom">
            <h5 class="ant-tooltip-open modal-title row-text file-title">{{ file.name }}</h5>
          </Tooltip>
          <button type="button" class="btn-close" @click='onClose'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe v-if='fileType === FILE_TYPE.PDF' :src='`${filePath}` + "#toolbar=0&navpanes=0"'
            style='width: 100%; height: 600px;'>
          </iframe>
          <img v-else-if='fileType === FILE_TYPE.IMAGE' :src='`${filePath}`' style='width: 100%; height: 600px;' />
          <h2 v-else-if='fileType === FILE_TYPE.NOT_SUPPORTED' class='text-center'>
            Файл доступен только для скачивания
          </h2>
          <div style='display: flex; width: 100%; justify-content: end; margin-top: 20px;'>
            <button type='button' class="btn btn-primary submit-btn" @click='downloadFileByUrl(url)'>Скачать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>

<script setup>
import { Tooltip } from "ant-design-vue";
import { getFileType, FILE_TYPE } from '@/shared/utils/file.ts'
import { downloadFileByUrl } from '@/shared/utils/download.ts'
import { computed, ref } from 'vue';
import config from "@/config.ts";
import { getCookie } from "@/shared/utils/cookie.ts";
const modalWindowRef = ref();

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose()
  }
}

const fileType = computed(() => {
  return getFileType(props.file.name)
})

const props = defineProps({
  file: Object,
  onClose: Function,
  downloadedFile: Object
})

const token = getCookie("token");
const filePath = config.BASE_URL + "/files/" + props.file.id + `?api_token=${token}`
const url = config.BASE_URL + "/files/" + `${props.downloadedFile ? props.downloadedFile.id : props.file.id}` + `/download?api_token=${token}`

</script>
