<template>
  <div class="tab-pane" :id="id">
    <div class="row">
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Организационно-правовая форма <span class="text-danger">*</span></label>
          <core-select-input :fieldsName='fieldsName' label='Организационно-правовая форма' name='company.type'
            :data='data' :options='regOptions' :disabled='blocked' :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Сокращенное наименование компании <span class="text-danger">*</span></label>
          <core-text-input name="company.short_name" :fieldsName='fieldsName' label='Сокращенное наименование компании'
            placeholder="Введите сокращенное наименование компании" :data='data' :disabled='blocked' :showError='true'
            :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Полное наименование компании</label>
          <core-text-input name="company.full_name" placeholder="Введите полное наименование компании" :data='data'
            :disabled='blocked' :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">ИНН</label>
          <core-numeric-input :data='data' name="company.tin" placeholder='123456789012' :fieldsName='fieldsName'
            label='ИНН' :mask="isIPRole ? '############' : '##########'" :disabled='blocked' :showError='true'
            :errors='errors' />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">{{ isIPRole ? 'ОГРН ИП' : 'ОГРН' }}</label>
          <core-numeric-input :data='data' name="company.ogrn" placeholder='123456789012'
            :mask="isIPRole ? '###############' : '#############'" :disabled='blocked' :fieldsName='fieldsName'
            :label="isIPRole ? 'ОГРН ИП' : 'ОГРН'" :showError='true' :errors='errors' />
        </div>
      </div>

      <div v-if="!isIPRole" class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">КПП</label>
          <core-numeric-input :data='data' name="company.kpp" placeholder='123456789' mask="#########"
            :fieldsName='fieldsName' label="КПП" :disabled='blocked' :showError='true' :errors='errors' />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">ОКПО</label>
          <core-numeric-input :data='data' name="company.okpo" placeholder='12345678' :fieldsName='fieldsName'
            label="ОКПО" :mask="isIPRole ? '##########' : '########'" :disabled='blocked' :showError='true'
            :errors='errors' />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">ОКВЭД</label>
          <core-text-input name="company.okved" placeholder='12.34.56' mask='AAAAAAAA' :stringOptions='okvedOptions'
            :data='data' :disabled='blocked' :fieldsName='fieldsName' label="ОКВЭД" :showError='true'
            :errors='errors' />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Номер телефона компании</label>
          <core-phone-input placeholder="+7 (912) 555 22 22" name='company.phone' :data='data'
            maskedValueFieldName='maskedCompanyPhone' :disabled='blocked' :fieldsName='fieldsName'
            label="Номер телефона компании" :showError='true' :errors='errors' />
        </div>
      </div>

      <h3>Юридический адрес компании:</h3>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Индекс</label>
          <core-numeric-input :data='data' name="company.addresses[0].index" placeholder='123456' mask="######"
            :disabled='blocked' :fieldsName='fieldsName' label="Индекс" :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Страна</label>
          <core-text-input name="company.addresses[0].country" placeholder="Введите сокращенное наименование компании"
            :data='data' :stringOptions='stringOptions' mask='A' :disabled='blocked' :showError='true'
            :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Город, населенный пункт</label>
          <core-text-input name="company.addresses[0].city" placeholder="г. Москва" :data='data' :disabled='blocked'
            :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Улица</label>
          <core-text-input name="company.addresses[0].street" placeholder="Ильфата Закирова" :data='data'
            :disabled='blocked' :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Дом</label>
          <core-text-input name="company.addresses[0].house" placeholder="25д" :data='data' :disabled='blocked'
            :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Корпус</label>
          <core-text-input name="company.addresses[0].building" placeholder="6" :data='data' :disabled='blocked'
            :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-block mb-3">
          <label class="col-form-label">Квартира, офис</label>
          <core-text-input name="company.addresses[0].apartment" placeholder="оф. 50" :data='data' :disabled='blocked'
            :showError='true' :errors='errors' />
        </div>
      </div>
      <div class="input-block mb-3">
        <core-checkbox-input name='company.is_actual_address_equal_legal' :data='data' :disabled='blocked'
          :isNumberRequired='true' :showError='true' :errors='errors'
          label='Юридический адрес совпадает с фактическим' />
      </div>

      <div v-if="!data['company.is_actual_address_equal_legal']" class='row'>
        <h3>Фактический адрес компании:</h3>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Индекс</label>
            <core-numeric-input :data='data' name="company.addresses[1].index" placeholder='123456' mask="######"
              :fieldsName='fieldsName' label="Индекс" :disabled='blocked' :showError='true' :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Страна</label>
            <core-text-input name="company.addresses[1].country" placeholder="Введите сокращенное наименование компании"
              :data='data' :stringOptions='stringOptions' mask='A' :disabled='blocked' :showError='true'
              :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Город, населенный пункт</label>
            <core-text-input name="company.addresses[1].city" placeholder="г. Москва" :data='data' :disabled='blocked'
              :showError='true' :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Улица</label>
            <core-text-input name="company.addresses[1].street" placeholder="Ильфата Закирова" :data='data'
              :disabled='blocked' :showError='true' :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Дом</label>
            <core-text-input name="company.addresses[1].house" placeholder="25д" :data='data' :disabled='blocked'
              :showError='true' :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Корпус</label>
            <core-text-input name="company.addresses[1].building" placeholder="6" :data='data' :disabled='blocked'
              :showError='true' :errors='errors' />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-block mb-3">
            <label class="col-form-label">Квартира, офис</label>
            <core-text-input name="company.addresses[1].apartment" placeholder="оф. 50" :data='data' :disabled='blocked'
              :showError='true' :errors='errors' />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, computed } from 'vue';
import { stringOptions, okvedOptions } from "@/shared/validation/index.ts";
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { COMPANY_TYPE_ENUM } from "@/shared/validation/enums.ts"

let regOptions = reactive([])

const props = defineProps({
  errors: Object,
  data: Object,
  blocked: Boolean,
  id: String,
  fieldsName: Object
})

const isIPRole = computed(() => {
  return props.data['company.type'] === COMPANY_TYPE_ENUM.IP
})

onMounted(async () => {
  try {
    const { company_types } = await getEnums('company_types', true);
    company_types && company_types.forEach(item => {
      regOptions.push(item)
    })
  } catch (e) {

  }
})


</script>