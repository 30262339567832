<template>
  <success-modal :title='toastrText.title' :body='toastrText.body' :button='toastrText.button'
    :onSuccess='onModalSuccess' :onHide='onModalSuccess' />
  <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#success-modal" ref='achorRef'></a>
  <div class="offcanvas offcanvas-end core-canvas__card show" tabindex="-1" id="employer_request"
    aria-labelledby="add_employeeLabel">
    <modal-header title='Запрос на регистрацию' :isShowSeparator='true' :onClose='() => onClose(null)' />
    <div class="offcanvas-body">
      <div class="card-body">
        <div class='row'>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Фамилия Имя Отчество</label>
              <core-text-input name='user_name' placeholder='Иванов Иван Иванович' :disabled="true" :data='data' />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Электронная почта</label>
              <core-email-input name='user_email' placeholder='test@example.com' :disabled="true" :data='data' />
            </div>
          </div>
        </div>
        <div class='row'>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Роль</label>
              <core-text-input name='user_role' placeholder='Иванов Иван Иванович' :disabled="true" :data='data' />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Мобильный номер телефона</label>
              <core-phone-input name='user_phone' maskedValueFieldName='maskedUserPhone'
                placeholder='+7 (912) 555 22 22' :disabled="true" :data='data' />
            </div>
          </div>
        </div>
        <div class='row'>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Организационно правовая форма </label>
              <core-text-input name='company_type' placeholder='Индивидуальный предприниматель (ИП)' :disabled="true"
                :data='data' />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="input-block mb-3">
              <label class="col-form-label">Сокращенное наименование компании</label>
              <core-text-input name='company_name' placeholder='ИП Петров Петр Петрович' :disabled="true"
                :data='data' />
            </div>
          </div>
        </div>
        <h3 class='mb-4 modal-subtitle'>Документы:</h3>
        <div class="pdf-file mt-2 d-flex" v-for='(file) in data.files'>
          <file :file='file' :onClick='openFile' />
        </div>
        <core-form v-if='data.status === "Новый"' :url='`/admin/register_requests/${id}/accept`'
          :onSuccess="() => onSuccess()" :auth='true' v-slot='{ isLoading }'>
          <div class="buttons_container">
            <core-submit-button className="btn btn-primary submit-btn" text='Принять' :isLoading='isLoading' />
            <button class="btn btn-primary submit-btn" type='button'>
              <a class="nav-link" href="javascript:void(0);" data-bs-toggle="modal"
                data-bs-target='#employer_request_decline'>Отклонить</a>
            </button>
          </div>
        </core-form>
        <div v-else-if='data.status === "Отклонено"' class='mt-4'>
          <h3 class='mb-4'>Причина отказа:</h3>
          <core-textarea-input :data='data' :disabled='true' name='rejection_reason'></core-textarea-input>
        </div>
      </div>
    </div>
  </div>
  <div id="employer_request_decline" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h2 class="modal-title text-bold">Укажите причину </h2>
          </div>
        </div>
        <div class="modal-body">
          <p class='gray-text'>Напишите сопроводительное письмо пользователю, о причине отказа *</p>
          <core-form :url='`admin/register_requests/${id}/reject`' :initialValidation='schema'
            v-slot="{ errors, isLoading }" :auth='true' :onSuccess="() => { onSuccess(true) }">
            <core-textarea-input name='rejection_reason' :data='data' :errors='errors' className='file-table'
              :showError='true' :maxLength='255' />
            <div class="buttons_container">
              <core-submit-button className="btn btn-primary submit-btn" text='Отправить'
                :className='!data.rejection_reason || data.rejection_reason.trim().length === 0 && "disabled"'
                :disabled='!data.rejection_reason || data.rejection_reason.trim().length === 0'
                :isLoading='isLoading' />
              <button class="btn btn-outline-primary submit-btn" type='button'><a class="nav-link"
                  href="javascript:void(0);" data-bs-toggle="modal"
                  data-bs-target='#employer_request_decline'>Отмена</a></button>
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <file-view-modal v-if='data.file' :file='data.file' :onClose='onRejectClose' />
  <div class="offcanvas-backdrop fade show" @click='() => onClose(null)'></div>
</template>
<script setup>
import { reactive, onMounted, ref } from 'vue'
import { schema } from "@/shared/validation/rejectRequest.ts";
import request from '@/shared/core-api/index.ts';
const achorRef = ref();

const props = defineProps({
  id: String,
  onClose: Function,
})

const onModalSuccess = () => {
  props.onClose(null)
}

const data = reactive({
  user_name: '',
  company_type: '',
  user_email: '',
  user_role: 'Ресурсный менеджер',
  user_phone: '',
  company_type: '',
  company_name: '',
  maskedUserPhone: '',
  rejection_reason: '',
  files: [],
  file: null,
  status: ''
})

const toastrText = reactive({
  title: 'Отличная работа!',
  body: `Новый пользователь успешно зарегистрирован. Учетные данные отправлены на почту ${data.user_email}`,
  button: 'Продолжить'
})

const onSuccess = (isReject = false) => {
  if (isReject) {
    toastrText.title = 'Запрос на регистрацию был отклонен.';
    toastrText.body = `Письмо с причиной отказа отправлено на почту ${data.user_email}`
  } else {
    toastrText.title = 'Отличная работа!';
    toastrText.body = `Новый пользователь успешно зарегистрирован. Учетные данные отправлены на почту ${data.user_email}`;
  }
  achorRef.value.click()
}

const openFile = (file) => {
  data.file = file
}

const onRejectClose = () => {
  data.file = null;
}

onMounted(async () => {
  try {
    const url = `/admin/register_requests/${props.id}`
    const { data: userData } = await request(url, {
      method: 'GET',
      auth: true,
    })

    Object.assign(data, userData);
    data.maskedUserPhone = userData.user_phone;
  } catch (e) {

  }
})

</script>
