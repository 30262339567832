<template>
  <div class="account-page">
    <success-modal :title='modalTitle' :body='modalBody' :button='modalButton' :onSuccess='modalCallback'
      :onHide='modalCallback' />
    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#success-modal" ref='achorRef'></a>
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <div class="account-logo">
            <router-link to="admin-users">
              <img src="@/assets/img/logo2.png" alt="Синкос" />
            </router-link>
          </div>

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Сбросить пароль</h3>

              <core-form :onSuccess="onSuccess" v-slot='{ errors, isLoading }' url='/reset_password'
                :initialValidation="schema" :additionalData='data'>
                <div class="input-block mb-4">
                  <core-password-input required :showLabel='true' label='Новый пароль' name='password' type="password"
                    :data='formData' :errors='errors' :showError='true' />
                </div>
                <div class="input-block mb-4">
                  <core-password-input required :showLabel='true' label='Подтвердите пароль'
                    name='password_confirmation' type="password" :data='formData' :errors='errors' :showError='true' />
                </div>
                <div class="input-block mb-4 text-center">
                  <core-submit-button className="btn btn-primary auth-button" type="submit" text='Обновить пароль'
                    :isLoading='isLoading' />
                </div>
              </core-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { useRoute } from "vue-router";
import { router } from "@/router";
import { schema } from "@/shared/validation/resetPassword.ts";
const achorRef = ref();
const modalTitle = 'Отличная работа!';
const modalBody = 'Ваш пароль успешно изменен.';
const data = useRoute().query;
const modalButton = 'Продолжить';

const onSuccess = (values) => {
  achorRef.value.click()
}

const modalCallback = () => {
  router.push('/')
}

const formData = reactive({
  password: '',
  password_confirmation: '',
})
</script>
