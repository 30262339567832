export const formatStringArrForCoreSelect = (arr) => {
  const newArr = [];
  arr.forEach((item) => {
    newArr.push({
      label: item,
      value: item,
    });
  });

  return newArr;
};
