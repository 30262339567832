<template>
  <div class="content container-fluid">
    <breadcrumb-header />
    <filter-search :updateServerOptions='updateServerOptions' />

    <core-table :headers='headers' url='/admin/companies' ref='tableRef'>
      <template #item-contracts_number='{ contracts }'>
        <div>
          {{ contracts && contracts[0] && contracts[0].number }}
        </div>
      </template>
      <template #item-contracts_start_date='{ contracts }'>
        <div>
          {{ fromTimestampToISOString(contracts && contracts[0] && contracts[0].start_date && contracts[0].start_date *
            1000) }}
        </div>
      </template>
      <template #item-contracts_end_date='{ contracts }'>
        <div>
          {{ fromTimestampToISOString(contracts && contracts[0] && contracts[0].end_date && contracts[0].end_date *
            1000) }}
        </div>
      </template>
      <template #item-actions="{ id }">
        <div class="text-end">
          <div class="dropdown dropdown-action">
            <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false"><i class="material-icons">more_vert</i></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" @click='setId(id)'>
                <span class='action-icon-container'>
                  <i class="fa fa-external-link"></i>
                </span>
                Открыть </a>
              <router-link class="dropdown-item" :to="{ name: 'admin-documents', query: { company_id: id } }">
                <span class='action-icon-container'>
                  <i class="fa-regular fa-file"></i>
                </span>
                Перейти к документам
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </core-table>
  </div>

  <div v-if='userId.id'>
    <open-company :onClose='setId' :id='userId.id'></open-company>
  </div>
</template>
<script setup>
import FilterSearch from "@/components/Filters/Search";
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { reactive, ref } from 'vue';
import { headers } from './companies.options.ts';
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

const userId = reactive({
  id: null
})

const setId = (id) => {
  userId.id = id;
  if (!id) {
    updateServerOptions();
  }
}

</script>
