<template>
  <div class="content container-fluid">
    <breadcrumb-header />

    <filter-search :updateServerOptions='updateServerOptions' />
    <core-table :headers='user.isITManagerRole ? iTManagerHeaders : purchasingManagerHeaders' url='/partners'
      ref='tableRef'>
      <template #item-phone='{ phone }'>
        <div>
          {{ phone && `+${phone}` }}
        </div>
      </template>
      <template #item-last_activity='{ last_activity }'>
        <div>
          {{ fromTimestampToISOString(last_activity && last_activity * 1000) }}
        </div>
      </template>
    </core-table>
  </div>
</template>
<script setup>
import FilterSearch from "@/components/Filters/Search";
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { ref } from 'vue';
import { useUserStore } from '@/stores/user.ts';
import { iTManagerHeaders, purchasingManagerHeaders } from './partners.options.ts'
const tableRef = ref();
const user = useUserStore();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}
</script>
