import { ref } from "vue";

export const useFormingModalRequest = () => {
  const createFormingRequestModal = ref({
    isShow: false,
    name: "",
  });

  const setFormingRequestModal = (isShow) => {
    createFormingRequestModal.value.isShow = isShow;
  };

  return {
    createFormingRequestModal,
    setFormingRequestModal,
  };
};
