<template>
  <!-- Add Employee Modal -->
  <div id="resource_release_card" class="modal custom-modal show d-block" role="dialog" ref='modalWindowRef'
    @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title text-bold">Оставить отклик </h5>
            <p class='gray-text text-center'>№ Запроса {{ name }}</p>
          </div>
          <button type="button" class="btn-close" @click='onClose(false)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class='text-bold'>Прикрепите до 3х резюме </h4>
          <p class='gray-text'>Поддерживаемый формат: {{ allowedExts.join(', ') }}</p>
          <core-form url="/tam_resources" :auth='true' :initialValidation='resourceSchema'
            v-slot='{ errors, isLoading, setErrors }' :additionalData='additionalData' :onSuccess='onSuccess'
            :onError='onError' :formatBeforeSubmit='formatBeforeSubmit'>
            <div v-for="i in 3">
              <h4 class='gray-text'>Резюме №{{ i }}</h4>
              <p>Ставка</p>
              <div class='mb-3'>
                <core-numeric-input :name='`responses[${i - 1}].pay_rate`' :data='data' :errors='errors'
                  :showError='true' mask="A" :maskOptions='numberStringOptions' />
              </div>
              <div class='mb-4 resource-container__btn-close position-relative'>
                <core-single-file-input :allowedExts='allowedExts' :data='data' :name='`responses[${i - 1}].file`'
                  :showError='true' :errors='errors' :setErrors='setErrors' />
              </div>
            </div>
            <div class="buttons_container">
              <core-submit-button :changeBeforeSubmit='true' type='button'
                :onClick='(e, submit) => validateBeforeSubmit(submit)' className="btn btn-primary submit-btn"
                text='Отправить отклик' :isLoading='isLoading' />
              <button class="btn btn-outline-primary submit-btn" type='button' @click='onClose(false)'>Отмена</button>
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>
<script setup>
import { resourceSchema } from '@/shared/validation/createResource.ts';
import { numberStringOptions } from "@/shared/validation/index.ts";
import { ref, reactive, onUnmounted } from 'vue';
import { useToast } from 'vue-toast-notification';
const $toast = useToast();
const props = defineProps({
  id: Number | String,
  name: { type: String },
  onClose: Function,
});

const modalWindowRef = ref();

const noFieldDetectedError = `
  <div class='error-core-container'>
    <div class='error-core-container__cross'>
      <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
    </div>
    <p class="py-0 my-0 w-100">Ошибка!</p>
    <p class="py-0 my-0 w-100">Вы не можете отправить пустой отклик</p>
  </div>
`

const data = reactive({
  'responses[0].pay_rate': '',
  'responses[0].file': '',
  'responses[1].pay_rate': '',
  'responses[1].file': '',
  'responses[2].pay_rate': '',
  'responses[2].file': '',
});

const serverSequence = reactive({
  0: null,
  1: null,
  2: null,
})

const additionalData = {
  tam_request_id: props.id
}

const allowedExts = ['DOC', 'DOCX', 'JPEG', 'PNG', 'XLS', 'CSV', 'PPT', 'TXT', 'RTF', 'PDF', 'TIFF'];

const validateBeforeSubmit = (submit) => {
  $toast.clear();
  let isFailed = true;
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      isFailed = false;
    }
  }

  if (isFailed) {
    createErrorToast(noFieldDetectedError);
  } else {
    submit();
  }

}

const createErrorToast = (template) => {
  $toast.open({
    type: 'error',
    message: template,
    duration: 0,
    position: 'bottom-left'
  });
}

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false)
  }
}

const onSuccess = () => {
  props.onClose(false);
}

const onError = (errors, setErrors) => {
  if (errors.data && errors.data.errors && errors.data.errors.responses) {
    const errorString = errors.data.errors.responses[0].split('.')
    const limitError = `
      <div class='error-core-container'>
        <div class='error-core-container__cross'>
          <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
        </div>
        <p class="py-0 my-0 w-100">Ошибка!</p>
        <p class="py-0 my-0 w-100">${errorString[0]}.</p>
        <p class="py-0 my-0 w-100">${errorString[1]}</p>
      </div>
    `
    createErrorToast(limitError);
  } else if (errors.data && errors.data.errors) {
    const serverErrors = errors.data.errors;
    if (typeof serverErrors === 'object' && !Array.isArray(serverErrors) && serverErrors !== null) {

      const newErrors = {}

      Object.keys(serverErrors).forEach(item => {
        let key = item;
        if (key.startsWith('responses')) {
          const firstDotIndex = key.indexOf('.');
          const secondDotIndex = key.indexOf('.', firstDotIndex + 1);
          const number = key.slice(firstDotIndex + 1, secondDotIndex);
          Object.entries(serverSequence).forEach((serverItem) => {
            const [serverKey, serverValue] = serverItem;
            if (serverValue === Number(number)) {
              setErrors({ [key]: null });
              const newKey = `responses.${serverKey}${key.slice(secondDotIndex)}`;
              newErrors[newKey] = serverErrors[item];
            }
            return;
          });
        }
      });

      setErrors(newErrors);
    }
  };
}

const formatBeforeSubmit = (values) => {
  serverSequence['0'] = null;
  serverSequence['1'] = null;
  serverSequence['2'] = null;
  const responses = values.responses;
  const newResponses = [];
  responses.forEach((item, index) => {
    if (!item.pay_rate && item.pay_rate !== 0 && !item.file) {

    } else {
      newResponses.push(item);
      serverSequence[index] = newResponses.length - 1
    }
  })
  const newValues = {
    responses: newResponses,
  };
  return newValues
}

onUnmounted(() => {
  $toast.clear();
});

</script>