<template>
  <Field :name="name" v-model='data[name]' placeholder="" :validateOnModelUpdate='false' :validateOnChange='false'
    :validateOnBlur='false'>
    <input type='file' :id='name' :onChange='filesOnChange' class="input-file" :class="{ 'is-invalid': errors[name] }"
      :accept="returnFileExt(allowedExts)" ref="inputRef" />
    <label :for="name">
      <div class='input-file__button'>
        <img :src='clipIcon' class='core-input-file__icon'>
        Выберите файл
      </div>
      <span class='input-file__text'>{{ fileDescription.name ? `${fileDescription.name}` :
        'Файл не выбран' }}
      </span>
    </label>
  </Field>
  <div class="invalid-feedback">{{ errors[name] }}</div>
  <div v-if='fileErrors'>
    <div class="error-container">{{ fileErrors }}</div>
  </div>
  <div v-if='data[name]' class="mt-1 d-flex flex-column">
    <div class='d-flex align-items-center'>
      <file :file='data[name]' className='register-file-name' />
      <div class='core-input-file__cross-button'>
        <button type="button" class="btn-close text-reset btn-sm" aria-label="Close"
          v-on:click='deleteFile(setErrors)'></button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue';
import { getFileExt } from '@/shared/utils/file.ts'
import { Field } from "vee-validate";
import { returnFileExt } from '../utils/exts.ts';
import clipIcon from '@/assets/img/icons/clip.svg';
const props = defineProps({
  data: Object,
  errors: Object,
  showError: Boolean,
  name: String,
  disabled: Boolean,
  allowedExts: {
    type: Array, default: []
  },
  maxFileSize: {
    type: Number, default: 52428800
  },
  setErrors: Function
});

const inputRef = ref();
const fileErrors = ref();
const fileDescription = reactive({
  name: ''
});

const filesOnChange = (event) => {
  fileErrors.length = 0;
  const file = event.target.files[0];
  if (file.size > props.maxFileSize) {
    fileErrors.value = 'Максимальный размер файла не должен превышать 50мб';
  };
  const ext = getFileExt(file.name);
  if (!props.allowedExts.includes(ext.toUpperCase())) {
    fileErrors.value = 'Некорректное расширение файла';
  };
  props.data[props.name] = file;
  fileDescription.name = file.name
}

const deleteFile = (setErrors) => {
  inputRef.value.value = null;
  setErrors({ [props.name]: null })
  fileErrors.length = 0;
  props.data[props.name] = null
  fileDescription.name = '';
}

</script>