import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const resourceSchema = Yup.object().shape({
  responses: Yup.array().of(
    Yup.object().shape({
      pay_rate: Yup.string().when("file", (file) => {
        if (file[0]) {
          return FieldsValidation.notEmptyString;
        }
      }),
      file: Yup.string()
        .nullable()
        .test("len", "Поле не может быть пустым", (val, validationContext) => {
          const pay_rate = validationContext.parent.pay_rate;
          if (pay_rate && !val) {
            return false;
          } else {
            return true;
          }
        }),
    })
  ),
});
