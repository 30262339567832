<template>
  <div></div>
</template>
<script setup>
import { watch } from 'vue';
import { useToast } from 'vue-toast-notification';
const $toast = useToast();
const props = defineProps({
  errors: Object,
  fieldsName: Object
})

const onChange = (errors) => {
  for (const [key, value] of Object.entries(errors)) {
    const fieldName = props.fieldsName[key] ? props.fieldsName[key] : key
    $toast.open({
      type: 'error',
      message: `
          <div class='error-core-container'>
            <div class='error-core-container__cross'>
              <b-button type="button" class="btn-close text-reset" aria-label="Close"></b-button>
            </div>
            <p class="py-0 my-0 w-100">Ошибка!</p>
            <p class="py-0 my-0 w-100">${fieldName}</p>
            <p class="py-0 my-0 w-100">${value}</p>
          </div>
        `,
      duration: 0,
      position: 'bottom-left'
    })
  }
}

watch(
  () => props.errors,
  (value) => {
    onChange(value)
  },
  { deep: true }
);

</script>