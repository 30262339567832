<template>
  <div>
    <button v-if='!isLoading' :class="className" :type="type" :disabled='disabled' @click='onClickHandler'>
      {{ text }}
    </button>
    <div v-else :class="className" class="loader-container">
      <span style="opacity: 0;">{{ text }}</span>
      <div class="spinner-border loader-cointainer__loader" :class='spinnerOptions' role="status"></div>
    </div>
    <button v-if='changeBeforeSubmit' type='submit' style='display: none;' ref='hiddenSubmitRef'></button>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const hiddenSubmitRef = ref()

const props = defineProps({
  text: String,
  type: {
    type: String, default: 'submit'
  },
  className: String,
  isLoading: Boolean,
  spinnerOptions: {
    type: String, default: 'text-light'
  },
  disabled: {
    type: Boolean, default: false,
  },
  onClick: {
    type: Function, default: null
  },
  changeBeforeSubmit: {
    default: false,
    type: Boolean,
  }
})

const hiddenSubmit = () => {
  hiddenSubmitRef.value.click();
}

const onClickHandler = (e) => {
  if (!props.onClick) {
    return;
  }

  props.onClick(e, hiddenSubmit);
}
</script>