<template>
  <div class="tab-pane" :id="id">
    <div>
      <core-table v-if='data.contracts' :headers='headers' :showPaginationCount='false' :showPagination='false'
        tableClassName='agreement__header' :isOfflineMode='true' :items='data.contracts' :minHeight='600'>
        <template #item-number='{}'>
          <core-text-input :data='data' name="company.contracts[0].number" mask='AA######'
            :stringOptions='agreementNumberOptions' :fieldsName='fieldsName' label='Номер договора' :errors='errors'
            :showError='true' />
        </template>
        <template #item-start_date='{}'>
          <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].start_date' :data='data'
            :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedStartDate' />
        </template>
        <template #item-end_date='{}'>
          <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].end_date' :data='data'
            :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedEndDate' />
        </template>
        <template #item-status='{ status }'>
          <div class='badge bg-inverse-success'>{{ status }}</div>
        </template>
      </core-table>
      <div v-if='!data.contracts' class='row mt-3'>
        <h4 class='text-center'>На данный момент нет ни одного договора</h4>
      </div>
    </div>
  </div>
</template>
<script setup>
import { agreementNumberOptions } from "@/shared/validation/index.ts";
const headers = [
  { text: "№ договора", value: "number", sortable: false, custom: false },
  { text: "Дата старта", value: "start_date", sortable: false, custom: true, width: 200 },
  { text: "Дата окончания", value: "end_date", sortable: false, custom: true, width: 200 },
  { text: "Статус", value: "status", sortable: false, custom: true },
];

defineProps({
  data: Object,
  errors: Object,
  id: String,
  fieldsName: Object
})
</script>