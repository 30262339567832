import { defineStore } from "pinia";
import { USER_ROLE_ENUM } from "@/shared/validation/enums.ts";

export const useUserStore = defineStore("user", {
  persist: true,
  state: () => {
    return {
      role: null,
      avatar: null,
      name: null,
    };
  },
  getters: {
    isAdminRole: (state) => {
      return state.role === USER_ROLE_ENUM.ADMIN;
    },
    isITManagerRole: (state) => {
      return state.role === USER_ROLE_ENUM.IT_MANAGER;
    },
    isPurchasingManagerRole: (state) => {
      return state.role === USER_ROLE_ENUM.PURCHASING_MANAGER;
    },
    isResourseManagerRole: (state) => {
      return state.role === USER_ROLE_ENUM.RESOURSE_MANAGER;
    },
  },
});
