export const headers = [
  { text: "ФИО", value: "name", sortable: true, custom: true, width: 300 },
  { text: "Электронная почта", value: "email", sortable: true, custom: false },
  {
    text: "Компания",
    value: "company_short_name",
    sortable: true,
    custom: true,
    width: 200,
  },
  {
    text: "Дата регистрации",
    value: "created_at",
    sortable: true,
    custom: true,
  },
  { text: "Роль", value: "role", sortable: true, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];
