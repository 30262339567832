<template>
  <div class="file-sidebar">
    <form class="file-search">
      <div class="input-group">
        <div class="input-group-text">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <input v-model='filters.search_query' type="text" class="form-control rounded-pill" placeholder="Поиск">
      </div>
    </form>
  </div>
</template>
<script setup>
import createDebounce from '@/shared/utils/debounce.ts'
import { defineProps, watch, reactive } from 'vue'
const props = defineProps({
  updateServerOptions: Function,
  initialData: Object,
  type: String,
  options: {
    type: Array, default: []
  },
});

const { updateServerOptions } = props;
const debounce = createDebounce()
const filters = reactive({
  search_query: props.initialData && props.initialData.id,
})

const onChange = () => {
  const filtersObj = {}

  props.options.forEach(option => {
    if (option.value === props.type) {
      filtersObj[option.value] = filters.search_query;
    } else {
      filtersObj[option.value] = null
    }
  })

  updateServerOptions(filtersObj)
}

watch(
  filters,
  () => {
    debounce(onChange);
  },
  { deep: true }
);

</script>
<style scoped>
.file-search .input-group-text {
  color: #666;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 0;
  background: transparent;
}

.input-group input {
  padding-left: 36px;
}

.file-sidebar {
  padding-bottom: 10px;
}
</style>
