<template>
  <router-child />
</template>

<script>
import RouterChild from '@/components/Child'

export default {
  name: 'LoginLayout',
  components: {
    RouterChild,
  },
}
</script>
