<template>
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus">
        <div class="input-group">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control floating py-0" v-model="filters.search_query" placeholder='Поиск' />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input name="company_id" className="filter-select" :data="filters" :options="companyOptions"
          valueProp='id' displayName='short_name' placeholder="Фильтровать по компании" />
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <core-select-input name="role" className="filter-select" :data="filters" :options="roleOptions"
          placeholder="Фильтровать по роли" />
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <button class="btn btn-success w-100 text-capitalize" @click="resetFilters">
        Сбросить фильтры
      </button>
    </div>
  </div>
</template>
<script setup>
import { defineProps, reactive, onMounted, ref } from 'vue'
import { getEnums } from '@/shared/core-api/getEnums.ts';
import request from '@/shared/core-api/index.ts';
import { formatStringArrForCoreSelect } from '@/shared/core-form/ui/core-select-input/utils/index.ts';
const props = defineProps({
  filters: Object,
  resetFilters: Function
});

const roleOptions = ref([]);
const companyOptions = ref([]);

onMounted(async () => {
  try {
    const { user_roles } = await getEnums(['user_roles'], {
      auth: true
    });

    const roles = user_roles.filter(item => item !== 'Администратор');
    roleOptions.value = formatStringArrForCoreSelect(roles);

    const { data: companiesResponse } = await request('/admin/companies/get_filter_list', {
      headers: {
        Accept: "application/json",
      },
      auth: true,
    });

    companyOptions.value = companiesResponse;
  } catch (e) {

  }
})

</script>
