<template>
  <h4>Общие сведения</h4>
  <div class='info-tab__container'>
    <div class='info-tab__container-cell'>
      <div class='request-card__cell'>
        <p class='gray-text'>Статус</p>
        <div>
          <span :class='getStatusClass(data.status)'>{{ data.status }}</span>
        </div>
      </div>
      <div class='request-card__cell'>
        <p class='gray-text'>Период</p>
        <p class='text-bold'>
          {{ fromTimestampToISOString(data.start_date * 1000) }}
          —
          {{ fromTimestampToISOString(data.end_date * 1000) }}
        </p>
      </div>
    </div>
    <div class='info-tab__container-cell'>
      <div class='request-card__cell'>
        <p class='gray-text'>Срок предоставления КП</p>
        <p class='text-bold'>{{ fromTimestampToISOString(data.kp_date * 1000) }} </p>
      </div>
      <div class='request-card__cell'>
        <p class='gray-text'>Финансирование</p>
        <p class='text-bold'>{{ data.funding_type }}</p>
      </div>
    </div>

  </div>
  <div class='request-card__container info-tab__container'>
    <div class='info-tab__container-cell'>
      <div class='request-card__cell'>
        <p class='gray-text'>Проект</p>
        <p class='text-bold'>{{ data.project && data.project.title }}</p>
      </div>
      <div class='request-card__cell'>
        <p class='gray-text'>Техническая область</p>
        <p class='text-bold'>{{ data.technical_field && data.technical_field.name }}</p>
      </div>
    </div>
    <div class='info-tab__container-cell'>
      <div class='request-card__cell'>
        <p class='gray-text'>Проектная роль</p>
        <p class='text-bold'>{{ data.project_role && data.project_role.name }}</p>
      </div>
      <div class='request-card__cell'>
        <p class='gray-text'>Грейд</p>
        <p class='text-bold'>{{ data.grade && data.grade.name }}</p>
      </div>
    </div>
  </div>

  <h3 class='text-bold mt-3'>Описание компетенции</h3>
  <p class='gray-text'>Описание задачи</p>
  <div class='text-bold mb-3 info-tab__text'>
    {{ data.description }}
  </div>
  <p class='gray-text'>Требования</p>
  <div class='text-bold mb-3 info-tab__text '>
    {{ data.tf_requirement && data.tf_requirement.text }}
  </div>
  <p class='gray-text'>Обязанности</p>
  <div class='text-bold mb-3 info-tab__text '>
    {{ data.tf_duty && data.tf_duty.text }}
  </div>
  <p class='gray-text'>Опыт аналогичных проектов</p>
  <div class='text-bold mb-3 info-tab__text'>
    {{ data.similar_experience }}
  </div>

  <h3 class='mb-4'>Документы</h3>
  <div class="pdf-file mt-2 d-flex" v-for='(file) in data.files'>
    <file :file='file' :onClick='openFile' />
  </div>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' />
</template>
<script setup>
import { getStatusClass } from '@/pages/requests/index.ts'
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';

const { selectedFile, openFile, closeFile } = useFileModal();
const props = defineProps({
  data: Object
})
</script>