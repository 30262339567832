export const iTManagerHeaders = [
  {
    text: "Компания",
    value: "company_short_name",
    custom: false,
    sortable: true,
  },
  {
    text: "Количество согласованных заявок",
    value: "application_count",
    sortable: false,
    custom: false,
  },
  {
    text: "Последняя активность",
    value: "last_activity",
    sortable: false,
    custom: true,
  },
  {
    text: "Активность за всё время",
    value: "responses_count",
    sortable: false,
    custom: false,
  },
];

export const purchasingManagerHeaders = [
  {
    text: "Компания",
    value: "company_short_name",
    custom: false,
    sortable: true,
  },
  { text: "Контактное лицо", value: "name", sortable: true, custom: false },
  { text: "Телефон", value: "phone", sortable: true, custom: true },
  { text: "Электронная почта", value: "email", sortable: true, custom: false },
  {
    text: "Количество согласованных заявок",
    value: "application_count",
    sortable: false,
    custom: false,
  },
  {
    text: "Последняя активность",
    value: "last_activity",
    sortable: false,
    custom: true,
  },
  {
    text: "Активность за всё время",
    value: "responses_count",
    sortable: false,
    custom: false,
  },
];
