export const headers = [
  { text: "Компания", value: "short_name", sortable: true, custom: false },
  {
    text: "Номер договора",
    value: "contracts_number",
    sortable: true,
    custom: true,
  },
  {
    text: "Дата начала",
    value: "contracts_start_date",
    sortable: true,
    custom: true,
  },
  {
    text: "Дата окончания",
    value: "contracts_end_date",
    sortable: true,
    custom: true,
  },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];
