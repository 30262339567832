export const formatStringToPhone = (phoneString) => {
  try {
    if (!phoneString) {
      return "";
    }

    return `+ 7 (${phoneString.slice(1, 4)}) ${phoneString.slice(
      4,
      7
    )} ${phoneString.slice(7, 9)} ${phoneString.slice(9, 11)}`;
  } catch (e) {
    return "";
  }
};
