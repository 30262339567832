<template>
  <div class="main-wrapper">
    <header-layout />
    <sidebar-layout />
    <div class="page-wrapper">
      <router-child class="overflow-hidden" />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user.ts';
const user = useUserStore();

onMounted(async () => {
  if (user.token) {
    const { data } = await request('/me', {
      auth: true
    });

    user.name = data.name;
    user.avatar = data.image_path;
  }
})
</script>
<script>
/*import echo from '@/plugins/echo'*/
import RouterChild from '@/components/Child'
import HeaderLayout from '@/components/LayoutComponents/Header'
import SidebarLayout from '@/components/LayoutComponents/Sidebar'
//app.component('layout-header', Header)
//app.component('layout-sidebar', Sidebar)
/*import LeftNavigationMenu from '@/components/LayoutComponents/LeftMenu'
import LayoutBreadcrumbs from '@/components/LayoutComponents/Breadcrumbs'
import LayoutTopbar from '@/components/LayoutComponents/Topbar'*/

export default {
  name: 'MainLayout',
  components: {
    HeaderLayout,
    SidebarLayout,
    /*LeftNavigationMenu,
    LayoutBreadcrumbs,
    LayoutTopbar,*/
    RouterChild,
  },
  data() {
    return {
      newVersionNotified: false,
      showInner: true,
    }
  },
  computed: {
    /*    ...mapGetters({
          user: 'auth/user',
        }),*/
    innerClass() {
      return this.$route?.meta?.viewWrapperClass
        ?? 'default-layout-body-main-inner'
    },
  },
  created() {
    /*    echo.join('App.NewVersion').listen('NewVersionDeployed', this.handleNewVersionEvent)
        this.getApplicationVersion().then(({ data }) => this.handleNewVersionEvent(data))*/
  },
  mounted() {
    /*    this.checkWebNotificationPermissions()
        this.setHomeAccount()*/
    /*    this.$tabsBroadcast.on('force-reload-page', this.resetAndEmitEvent)
        this.$root.on('reset-states', this.resetInnerContent)*/
  },
  beforeUnmount() {
    /*    echo.leave('App.NewVersion')
        this.$tabsBroadcast.off('force-reload-page', this.resetAndEmitEvent)
        this.$root.off('reset-states', this.resetInnerContent)*/
  },
  methods: {
    /*    ...mapActions({
          resetStatesAndReload: 'resetStatesAndReload',
          getHomeAccount: 'utils/getHomeAccount',
          setHomeAccountId: 'accounts/setHomeAccount',
          sendWeb : 'notifications/sendWeb',
          getApplicationVersion: 'utils/getApplicationVersion',
        }),*/
    setHomeAccount() {
      /*      this.getHomeAccount().then(r => {
              const id = r.data?.id
              this.setHomeAccountId(id)
            })*/
    },
    /*    handleNewVersionEvent(data) {
          if (import.meta.env.VUE_APP_VERSION === data.version) return
          if (this.newVersionNotified) return
          this.newVersionNotified = true
          if (document.hidden && pageIsLastActive()) {
            this.sendWeb({
              title: 'A new version of Convy is available',
              description: 'Click to reload page and enjoy new features',
              onclick: this.resetEventBroadcast,
            })
          }
          this.$notify.info({
            title: 'New version available',
            description: 'Press <span class="font-bold">Update</span> button to enjoy new features',
            placement: 'tr',
            duration: 0,
            closable: false,
            splitActions: [
              {
                listeners: { click: () => this.resetEventBroadcast() },
                content: 'Update',
              },
              {
                color: 'lightgray',
                listeners: { click: (id) => this.$notify.close(id) },
                content: 'Close',
              },
            ],
          })
        },*/
    /*    checkWebNotificationPermissions() {
          if (typeof Notification === "undefined") return
          if (Notification.permission === 'default') {
            Notification.requestPermission()
          }
        },*/
    /*    resetEventBroadcast() {
          this.$tabsBroadcast.emit('force-reload-page')
          this.resetAndEmitEvent()
        },*/
    /*    resetAndEmitEvent() {
          this.$root.emit('force-reload-page')
          this.resetStatesAndReload()
        },*/
    resetInnerContent() {
      this.showInner = false
      this.$nextTick(async () => {
        /*FetchGeneralData().then(() => this.showInner = true)*/
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  max-height: -webkit-fill-available;
  @apply h-screen flex overflow-hidden bg-white sm:bg-gray-100;

  & .page-wrapper {
    @apply w-full overflow-y-auto;
  }

  &>.default-layout-body {
    @apply flex flex-col w-0 flex-1 overflow-hidden;

    &>.default-layout-body-main {
      @apply flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none h-full;

      &>.default-layout-body-main-inner {
        @apply mx-auto p-0 sm:p-4 lg:p-6 xl:p-8;
      }
    }
  }
}
</style>
