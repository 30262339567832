<template>
  <div id="success-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref='modalRef'>
    <div class="modal-dialog modal-sm">
      <div class="modal-content modal-filled bg-success">
        <div class="modal-body p-4">
          <div class="text-center">
            <i class="dripicons-checkmark h1 text-white"></i>
            <h4 class="mt-2 text-white">{{ title }}</h4>
            <p class="mt-3 text-white text-break">
              {{ body }}
            </p>
            <b-button variant="light my-2" data-bs-dismiss="modal" v-on:click='onSuccess'>
              {{ button }}
            </b-button>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
const modalRef = ref()
const props = defineProps({
  title: String,
  body: String,
  button: String,
  onSuccess: {
    type: Function, default: () => { }
  },
  onHide: Function,
})

const hideHandler = (event) => {
  props.onHide();
}

onMounted(() => {
  props.onHide && modalRef.value.addEventListener('hidden.bs.modal', hideHandler)
})

onBeforeUnmount(() => {
  props.onHide && modalRef.value.removeEventListener('hidden.bs.modal', hideHandler);
})

</script>