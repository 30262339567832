const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

// timestamp => d month_name year_count+г.
export const fromTimestampToString = (timestamp) => {
  if (!timestamp) {
    return "";
  }
  let date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day} ${month} ${year} г.`;
};

export const fromTimestampToISOString = (timestamp, symbol = ".") => {
  if (!timestamp) {
    return "";
  }
  let date = new Date(timestamp);
  let month = String(date.getMonth() + 1);
  if (month.length === 1) {
    month = `0${month}`;
  }

  let day = String(date.getDate());

  if (day.length === 1) {
    day = `0${day}`;
  }

  const year = date.getFullYear();
  return `${day}${symbol}${month}${symbol}${year}`;
};

export const createISOString = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);
  const isoString = localISOTime.split("T")[0];
  return isoString;
};

export const fromStringToTimePickerFormat = (string) => {
  const time = string.split(/\:|\-/g);
  return {
    hours: time[0],
    minutes: time[1],
  };
};
