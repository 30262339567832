export const companyHeaders = [{ text: "", value: "short_name", custom: true }];

export const fileHeaders = [{ text: "", value: "files", custom: true }];

export const fileTableHeaders = [
  { text: "Название файла", value: "name", custom: false },
  { text: "Действие", value: "actions", custom: true },
];

export const searchOptions = [
  { value: "id", label: "Поиск по идентификатору " },
  { value: "search_query", label: "Поиск по названию" },
];
