import { createRouter, createWebHistory } from "vue-router";
import { ContractModelEnum } from "@/shared/enums/index.ts";
import { LoginPage } from "@/pages/login/index.ts";
import { RegisterPage } from "@/pages/register/index.ts";
import { ForgotPasswordPage } from "@/pages/forgotPassword/index.ts";
import { ResetPasswordPage } from "@/pages/resetPassword/index.ts";
import { AdminUsersPage } from "@/pages/adminUsers/index.ts";
import { Notifications } from "@/pages/notifications/index.ts";
import { RegisterRequests } from "@/pages/registerRequests/index.ts";
import { CompaniesPage } from "@/pages/companies/index.ts";
import { DocumentsRegister } from "@/pages/documentsRegister/index.ts";
import { AdminCompetencies } from "@/pages/competencies/index.ts";
import { MyProjects } from "@/pages/myProjects/index.ts";
import {
  ItRequestTM,
  ItRequestFixPrice,
  ResRequestTM,
  ResRequestFix,
} from "@/pages/requests/index.ts";
import { Bid } from "@/pages/bid/index.ts";
import { Partners } from "@/pages/partners/index.ts";
import { Dashboard } from "@/pages/dashboard/index.ts";
import { ResourceCompetencies } from "@/pages/competencies/index.ts";

import { useUserStore } from "@/stores/user.ts";
import { USER_ROLE_ENUM } from "@/shared/validation/enums.ts";
import { ErrorPage } from "@/pages/error/index.ts";
import { wrappedIsCheckCompetenciesForResourseManager } from "@/pages/competencies/index.ts";
import { NotFoundPage } from "@/pages/notFound/index.ts";

const resourceManagerMenu = [
  { title: "Настройка профиля", key: "profile" },
  {
    title: "Настройка компании",
    key: "company",
    target: "#company_settings",
    toggle: "offcanvas",
  },
];

const setProtected = (next, allowedRoles) => {
  const user = useUserStore();
  const userRole = user.role;

  if (!userRole) {
    router.push("/");
    return;
  }

  let isAllow = false;
  allowedRoles.forEach((role) => {
    if (userRole === role) {
      isAllow = true;
      return;
    }
  });

  if (isAllow) {
    next();
  } else {
    router.push("/error");
  }
};

const routes = [
  //auth pages
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordPage,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordPage,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
  },

  //admin pages
  {
    path: "/admin-users",
    name: "admin-users",
    component: AdminUsersPage,
    meta: {
      title: "Список пользователей",
      parent: "users",
      parentTitle: "Пользователи",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.ADMIN]);
    },
  },
  {
    path: "/admin-requests",
    name: "admin-requests",
    component: RegisterRequests,
    meta: {
      title: "Запросы на регистрацию",
      parent: "users",
      parentTitle: "Пользователи",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.ADMIN]);
    },
  },
  {
    path: "/admin-companies",
    name: "admin-companies",
    component: CompaniesPage,
    meta: {
      title: "Компании",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.ADMIN]);
    },
  },
  {
    path: "/admin-documents",
    name: "admin-documents",
    component: DocumentsRegister,
    meta: {
      title: "Реестр документов",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.ADMIN]);
    },
  },
  {
    path: "/admin-competencies",
    name: "admin-competencies",
    component: AdminCompetencies,
    meta: {
      title: "Справочник компетенций",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.ADMIN,
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: {
      title: "Уведомления",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.RESOURSE_MANAGER,
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  //it mananger pages
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Главная",
      topbar: `Портал управления ресурсами`,
      userMenu: [],
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.RESOURSE_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: MyProjects,
    meta: {
      title: "Мои проекты",
      topbar: `Портал управления ресурсами`,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
        USER_ROLE_ENUM.RESOURSE_MANAGER,
      ]);
    },
  },
  {
    path: "/itmanager-resources-request",
    name: "itmanager-resources-request",
    component: ItRequestTM,
    meta: {
      title: "Запросы на ресурсы T&M",
      parent: "request",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Запросы",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  {
    path: "/itmanager-fixprice-request",
    name: "itmanager-fixprice-request",
    component: ItRequestFixPrice,
    meta: {
      title: "Модель Fix Priced",
      parent: "request",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Запросы",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  {
    path: "/bid",
    name: "bid",
    component: Bid,
    meta: {
      title: "Заявки на ресурсы T&M",
      parent: "bid",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Заявки",
      mode: ContractModelEnum.TAM,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
        USER_ROLE_ENUM.RESOURSE_MANAGER,
      ]);
    },
  },
  {
    path: "/fixprice-bid",
    name: "fixprice-bid",
    component: Bid,
    meta: {
      title: "Заявки на Fix Priced",
      parent: "bid",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Заявки",
      mode: ContractModelEnum.FIX_PRICE,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
        USER_ROLE_ENUM.RESOURSE_MANAGER,
      ]);
    },
  },
  {
    path: "/partners",
    name: "partners",
    component: Partners,
    meta: {
      title: "Партнеры",
      parent: "ref",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Справочники",
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [
        USER_ROLE_ENUM.IT_MANAGER,
        USER_ROLE_ENUM.PURCHASING_MANAGER,
      ]);
    },
  },
  {
    path: "/resource-resources-request",
    name: "resource-resources-request",
    component: ResRequestTM,
    meta: {
      title: "Запросы на ресурсы T&M",
      parent: "resourcerequest",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Запросы",
      resourceManagerMenu,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.RESOURSE_MANAGER]);
    },
  },
  {
    path: "/resource-fixprice-request",
    name: "resource-fixprice-request",
    component: ResRequestFix,
    meta: {
      title: "Модель Fix Priced",
      parent: "resourcerequest",
      topbar: `Портал управления ресурсами`,
      parentTitle: "Запросы",
      resourceManagerMenu,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.RESOURSE_MANAGER]);
    },
  },
  {
    path: "/resource-competencies",
    name: "resource-competencies",
    component: ResourceCompetencies,
    meta: {
      title: "Компетенции",
      parent: "resourceref",
      topbar: `Портал управления ресурсами`,
      resourceManagerMenu,
    },
    beforeEnter: (to, from, next) => {
      setProtected(next, [USER_ROLE_ENUM.RESOURSE_MANAGER]);
    },
  },
  { path: "/404", name: "not-found", component: NotFoundPage },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

export const router = createRouter({
  history: createWebHistory(""),
  linkActiveClass: "false",
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });
  const user = useUserStore();
  if (user.isResourseManagerRole) {
    wrappedIsCheckCompetenciesForResourseManager();
  }
  // Continue with the navigation
  next();
});
