export const headers = [
  {
    text: "ID запроса",
    value: "number",
    sortable: true,
    custom: false,
    width: 110,
  },
  { text: "Название", value: "name", sortable: true, custom: false },
  { text: "Статус", value: "status", sortable: true, custom: true },
  { text: "Проект", value: "project_name", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "Отклики", value: "responses_count", sortable: true, custom: false },
  {
    text: "Срок предоставления КП",
    value: "kp_date",
    sortable: true,
    custom: true,
  },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];
