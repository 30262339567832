import { useRoute } from "vue-router";
import createDebounce from "@/shared/utils/debounce.ts";
import { setParams } from "@/shared/utils/updateQueryParams.ts";
import { watch, reactive, ref } from "vue";

export const useAdminUsersFilters = (updateServerOptions) => {
  const route = useRoute();
  const initialParams = route.query;
  const debounce = createDebounce();
  const initialPage = initialParams.page || 1;
  const isResetPage = ref(false);

  const initialFiltersState = {
    search_query: initialParams.search_query || "",
    company_id: initialParams.company_id || "",
    role: initialParams.role || "",
    blocked: initialParams.blocked || null,
  };

  const filters = reactive({
    search_query: initialParams.search_query || "",
    company_id: initialParams.company_id || "",
    role: initialParams.role || "",
    blocked: initialParams.blocked ? Number(initialParams.blocked) : null,
  });

  const onChange = () => {
    setParams({ ...filters });
    updateServerOptions({ ...filters }, isResetPage.value);
    isResetPage.value = false;
  };

  watch(
    filters,
    () => {
      debounce(onChange);
    },
    { deep: true }
  );

  const setBlockedMode = (value) => {
    isResetPage.value = true;
    filters.blocked = value;
  };

  const resetFilters = () => {
    filters.search_query = "";
    filters.company_id = "";
    filters.role = "";
  };

  return {
    setBlockedMode,
    filters,
    initialFiltersState,
    initialPage,
    resetFilters,
  };
};
