export const BidStatusesEnums = {
  NEW: "Новый",
  UNDER_CONSIDERATION: "На рассмотрении",
  AWAITING_CONFIRMATION: "Ожидает подтверждения",
  AGREED: "Согласовано",
  AGREED_WITH_IT_MANAGER: "Согласовано ИТ менеджером",
  AGREED_WITH_PURCHASING_MANAGER: "Согласовано Менеджером закупок",
  AGREED_WITH_RESOURSE_MANAGER: "Согласовано Ресурсным менеджером",
  SIGNED_IN_EDM: "Подписано в ЭДО",
  DECLINED_WITH_IT_MANAGER: "Отклонено ИТ менеджером",
  DECLINED_WITH_PURCHASING_MANAGER: "Отклонено Менеджером закупок",
  DECLINED_WITH_RESOURSE_MANAGER: "Отклонено Ресурсным менеджером",
  DECLINED: "Отклонено",
};

export const BidRequestModeEnum = {
  APPLY: "accept",
  REJECT: "decline",
  CONFIRM: "confirm-edm-sign",
};
