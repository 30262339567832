export const managersHeaders = [
  { text: "ID заявки", value: "number", sortable: true, custom: false },
  { text: "Проект", value: "project_name", sortable: true, custom: true },
  {
    text: "Исполнитель",
    value: "company_short_name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const resourceManagerHeaders = [
  { text: "ID заявки", value: "number", sortable: true, custom: false },
  { text: "Проект", value: "project_name", sortable: true, custom: true },
  { text: "Статус", value: "status", sortable: true, custom: true },
  { text: "Дата создания", value: "created_at", sortable: true, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const TAM_URL = "/tam_applications";
export const FIX_PRICE_URL = "/fp_applications";
