import { useUserStore } from "@/stores/user.ts";

export const getHomeUrl = () => {
  const user = useUserStore();

  if (user.isAdminRole) {
    return "/admin-users";
  }

  if (
    user.isITManagerRole ||
    user.isPurchasingManagerRole ||
    user.isResourseManagerRole
  ) {
    return "/dashboard";
  }

  return "/";
};
