<template>
  <div class="card w-100">
    <div class="card-body apexchart-card">
      <span class="badge badge-soft-primary apexchart-card__id">{{ id }}</span>
      <h3 class='apexchart-card__title'>{{ name }}</h3>
      <div class='apexchart-card__data-container'>
        <div class='apexchart-card__stats-container'>
          <div>
            <p class='stats-container__header-title'>Новые</p>
            <p class='stats-container__counter'>
              <span class='stats-container__legend-icon' style='background-color: #2FCBFF;'></span>
              {{ project.counts['Новый'] }}
            </p>
          </div>
          <div>
            <p class='stats-container__header-title'>Согласовано</p>
            <p class='stats-container__counter'>
              <span class='stats-container__legend-icon' style='background-color: #00BE64;'></span>
              {{ project.counts['Согласовано'] }}
            </p>
          </div>
          <div>
            <p class='stats-container__header-title'>На рассмотрении</p>
            <p class='stats-container__counter'>
              <span class='stats-container__legend-icon' style='background-color: #7460EE;'></span>
              {{ project.counts['На рассмотрении'] }}
            </p>
          </div>
        </div>
        <div class='apexchart-card__chart-container'>
          <apexchart type="donut" height="125" width='125' :options="chartOptions" :series="getSeries(project)">
          </apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { chartOptions } from './apexChartCard.options.ts'
const props = defineProps({
  id: String,
  name: String,
  project: Object
})

const getSeries = (project) => {
  const countsObj = project.counts;
  return countsObj ? [countsObj['Новый'], countsObj['Согласовано'], countsObj['На рассмотрении']] : []
}

</script>
<style></style>