export const States = {
  TECHINAL_FIELD: "technical_fields",
  PROJECT_ROLES: "project_roles",
  GRADES: "grades",
  TF_DUTIES: "tf_duties",
  TF_REQUIREMENTS: "tf_requirements",
};

export const tabs = [
  {
    title: "Техническая область",
    key: States.TECHINAL_FIELD,
  },
  {
    title: "Проектная роль",
    key: States.PROJECT_ROLES,
  },
  {
    title: "Грейд",
    key: States.GRADES,
  },
  {
    title: "Требования",
    key: States.TF_REQUIREMENTS,
  },
  {
    title: "Обязанности",
    key: States.TF_DUTIES,
  },
];
