export const managersHeaders = [
  { text: "ID проекта", value: "number", sortable: true, custom: false },
  { text: "Название", value: "name", sortable: true, custom: false },
  { text: "Бюджет", value: "budget", sortable: true, custom: true },
  {
    text: "Описание",
    value: "description",
    sortable: true,
    custom: false,
    width: 350,
  },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const resourceManagerHeaders = [
  { text: "ID проекта", value: "number", sortable: true, custom: false },
  { text: "Название", value: "name", sortable: true, custom: false },
  {
    text: "Описание",
    value: "description",
    sortable: true,
    custom: false,
    width: 350,
  },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];
