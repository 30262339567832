import { BidStatusesEnums } from "./enums.ts";

export const getStatus = (status) => {
  let statusKey;
  for (const [key, value] of Object.entries(BidStatusesEnums)) {
    if (value === status) {
      statusKey = key;
      break;
    }
  }

  return statusKey;
};

export const statusesSchema = {
  NEW: {
    class: "badge rounded-pill bg-inverse-info",
  },
  UNDER_CONSIDERATION: {
    class: "badge rounded-pill status_under-considiration",
  },
  AWAITING_CONFIRMATION: {
    class: "badge rounded-pill bg-inverse-warning",
  },
  AGREED: {
    class: "badge rounded-pill bg-inverse-success",
  },
  AGREED_WITH_IT_MANAGER: {
    class: "badge rounded-pill bg-inverse-success",
  },
  AGREED_WITH_PURCHASING_MANAGER: {
    class: "badge rounded-pill bg-inverse-success",
  },
  AGREED_WITH_RESOURSE_MANAGER: {
    class: "badge rounded-pill bg-inverse-success",
  },
  SIGNED_IN_EDM: {
    class: "badge rounded-pill bg-inverse-info",
  },
  DECLINED_WITH_IT_MANAGER: {
    class: "badge rounded-pill badge-soft-danger",
  },
  DECLINED_WITH_PURCHASING_MANAGER: {
    class: "badge rounded-pill badge-soft-danger",
  },
  DECLINED_WITH_RESOURSE_MANAGER: {
    class: "badge rounded-pill badge-soft-danger",
  },
  DECLINED: {
    class: "badge rounded-pill badge-soft-danger",
  },
};

export const getStatusPermission = (status, allowedStatuses) => {
  return allowedStatuses.includes(status);
};

export const getStatusClass = (status) => {
  const statusKey = getStatus(status);
  return statusKey ? statusesSchema[statusKey].class : statusesSchema.NEW.class;
};
