import { ref } from "vue";

export function useFileModal() {
  const selectedFile = ref(null);
  const downloadedFile = ref(null);
  const openFile = (file, downloadFile = null) => {
    selectedFile.value = file;
    if (downloadFile) {
      downloadedFile.value = downloadFile;
    }
  };

  const closeFile = () => {
    selectedFile.value = null;
    downloadedFile.value = null;
  };

  return {
    downloadedFile,
    selectedFile,
    openFile,
    closeFile,
  };
}
