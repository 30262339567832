import { useRoute } from "vue-router";
import createDebounce from "@/shared/utils/debounce.ts";
import { setParams } from "@/shared/utils/updateQueryParams.ts";
import { watch, reactive, ref } from "vue";

export const useTMRequestsFilters = (updateServerOptions) => {
  const route = useRoute();
  const initialParams = route.query;
  const debounce = createDebounce();
  const initialPage = initialParams.page || 1;
  const isResetPage = ref(false);

  // Вся логика с фильтрами
  const initialFiltersState = {
    search_query: initialParams.search_query || "",
    status: initialParams.status || "",
    work_type: initialParams.work_type || "",
    project_id: initialParams.project_id || "",
    archived: initialParams.archived || null,
  };

  const filters = reactive({
    search_query: initialParams.search_query || "",
    status: initialParams.status || "",
    work_type: initialParams.work_type || "",
    project_id: initialParams.project_id || "",
    archived: initialParams.archived ? Number(initialParams.archived) : null,
  });

  const onChange = () => {
    setParams({ ...filters });
    updateServerOptions({ ...filters }, isResetPage.value);
    isResetPage.value = false;
  };

  watch(
    filters,
    () => {
      debounce(onChange);
    },
    { deep: true }
  );

  const setArchiveMode = (value) => {
    isResetPage.value = true;
    filters.archived = value;
    if (value === 1) {
      filters.status = "";
    }
  };

  const resetFilters = () => {
    filters.search_query = "";
    filters.status = "";
    filters.work_type = "";
    filters.project_id = "";
  };

  return {
    setArchiveMode,
    filters,
    initialFiltersState,
    initialPage,
    resetFilters,
  };
};
