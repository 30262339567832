import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const schema = Yup.object().shape({
  number: FieldsValidation.agreementNumber,
  executor: Yup.array().of(
    Yup.object().shape({
      text: FieldsValidation.textarea,
    })
  ),
});
