export const resourceTMHeaders = [
  {
    text: "ID запроса",
    value: "tam_request_number",
    sortable: true,
    custom: false,
  },
  {
    text: "Название",
    value: "tam_request_name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  {
    text: "Дата создания",
    value: "created_at",
    sortable: true,
    custom: true,
  },
  { text: "Ставка", value: "pay_rate", sortable: true, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const tableOptions = {
  requests: {
    url: "/tam_requests",
    headers: [
      { text: "ID запроса", value: "number", sortable: true, custom: false },
      { text: "Название", value: "name", sortable: true, custom: false },
      { text: "Статус", value: "status", sortable: true, custom: true },
      {
        text: "Количество специалистов",
        value: "workers_count",
        sortable: true,
        custom: false,
      },
      { text: "Проект", value: "project_name", sortable: true, custom: true },
      {
        text: "Дата создания",
        value: "created_at",
        sortable: true,
        custom: true,
      },
      {
        text: "Отклики",
        value: "responses_count",
        sortable: true,
        custom: false,
      },
      { text: "Тип", value: "work_type", sortable: true, custom: false },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ],
  },
  resources: {
    url: "/tam_resources",
    headers: resourceTMHeaders,
  },
};
