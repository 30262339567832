<template>
  <div class="content container-fluid">
    <breadcrumb-header />
    <filter-search :updateServerOptions='updateServerOptions' />

    <div class="row">
      <div class="col-md-12">
        <core-table url='/projects' :headers='user.isResourseManagerRole ? resourceManagerHeaders : managersHeaders'
          ref='tableRef' :minHeight='240' :initialData='tableInitialData'>
          <template v-if='user.isITManagerRole' v-slot:placeholder>
            <div>
              <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                <li class="nav-item">
                  <a class="nav-link active h-100 rounded-tabs" @click='setArchiveMode(0, true)'
                    href="#solid-rounded-justified-tab1" data-bs-toggle="tab">Активные</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" @click='setArchiveMode(1, true)'
                    href="#solid-rounded-justified-tab2" data-bs-toggle="tab">В архиве</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" @click='setProjectModal(true)' style="min-width:max-content">+
                    Создать проект</a>
                </li>
              </ul>
            </div>
          </template>
          <template #item-budget='{ budget }'>
            {{ budget && Number(budget).toLocaleString() }} руб
          </template>
          <template #item-actions="{ id, archived }">
            <div class="text-end">
              <div class="dropdown dropdown-action">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right" style='width: 180px;'>
                  <a class="dropdown-item" href="javascript:;" @click='setProjectModal(true, id, true)'>
                    <span class='action-icon-container'>
                      <i class="fa fa-external-link"></i>
                    </span>
                    Открыть проект
                  </a>
                  <a v-if='user.isITManagerRole && !archived' class="dropdown-item" href="javascript:;"
                    @click='setProjectModal(true, id)'>
                    <span class='action-icon-container'>
                      <i class="fa-solid fa-pencil"></i>
                    </span>
                    {{ true && 'Редактировать' }}
                  </a>
                  <a v-if='user.isITManagerRole' class="dropdown-item" href="javascript:;"
                    @click='setArchiveModeForData(id, archived)'>
                    <span class='action-icon-container'>
                      <img style='margin-left: -3px;' :src='archived ? outArchiveIcon : archiveIcon' />
                    </span>
                    {{ archived ? 'Вернуть в активные' : 'В архив' }}
                  </a>
                </div>
              </div>
            </div>
          </template>
        </core-table>
      </div>
    </div>
  </div>
  <create-project-modal v-if='projectModal.isShow' :id='projectModal.id' :onClose='setProjectModal'
    :isReadOnly='projectModal.readOnly' />
</template>
<script setup>
import FilterSearch from "@/components/Filters/Search";
import request from '@/shared/core-api/index.ts';
import { reactive, ref } from 'vue';
import archiveIcon from '@/assets/img/icons/archive.svg';
import outArchiveIcon from '@/assets/img/icons/unarchive.svg';
import { useUserStore } from '@/stores/user.ts';
import { managersHeaders, resourceManagerHeaders } from './myProject.options.ts'
const tableRef = ref();
const projectModal = reactive({
  id: null,
  isShow: false,
  readOnly: false,
})

const user = useUserStore()

const tableInitialData = {
  archived: user.isITManagerRole ? 0 : null,
}

const archiveMode = ref(0);

const setArchiveMode = (mode, resetPage = false) => {
  archiveMode.value = mode
  updateServerOptions({ archived: archiveMode.value }, resetPage)
}

const updateServerOptions = (data, resetPage = false) => {
  tableRef.value.updateServerOptions(data, resetPage)
}

const setArchiveModeForData = async (id, archived) => {
  const url = archived ? `/projects/${id}/in_active` : `/projects/${id}/in_archive`
  try {
    await request(url, {
      method: 'POST',
      auth: true
    });
    updateServerOptions();
  } catch (e) {

  }
}

const setProjectModal = (isShow, id, isReadOnly) => {
  if (!isShow) {
    projectModal.isShow = false;
    projectModal.id = null;
    projectModal.readOnly = false;
    updateServerOptions();
    return;
  }

  if (id) {
    projectModal.id = id;
  }

  if (isReadOnly) {
    projectModal.readOnly = isReadOnly;
  }

  projectModal.isShow = true;
}

</script>
