import { ref } from "vue";

export const useResourceCard = (id) => {
  const resourceCardState = ref({
    isShow: false,
    name: "",
    id: id,
  });

  const showResourceCardState = (isShow) => {
    resourceCardState.value.isShow = isShow;
  };

  return {
    resourceCardState,
    showResourceCardState,
  };
};
