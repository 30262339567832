<template>
  <div class="content container-fluid">
    <breadcrumb-header />

    <div class='documents-container'>
      <div class='documents-container__company_table'>
        <core-select-input className='filterSelect' :data='selectedType' name='type' :options='searchOptions' />
        <filter-search v-if='isShowAnything' :updateServerOptions='updateTableServerOptions'
          :initialData='initialCompanyRefData' :type='selectedType.type' :options='searchOptions' />
        <core-table v-if='isShowAnything' :initialData='initialCompanyRefData' :showHeader='false'
          url='/admin/file_companies' :headers='companyHeaders' ref='tableCompanyRef'
          paginationStyle='company-table__pagination' :customEmptySlot='true'
          :afterLoadhandler='afterCompanyLoadhandler' tableClassName='company-table__rows'>
          <template #item-short_name='{ short_name, id }'>
            <div @click='selectCompany(id)' role='button' :class="selectedCompany == id && 'company-item_active'">
              {{ short_name }}
            </div>
          </template>
          <template #empty-slot>
            <h2>Список компаний пуст</h2>
          </template>
        </core-table>
      </div>
      <div v-if='!isFirstLoad' class='files-table'>
        <core-table v-if='isShowTable' :initialData='initialDocumentsTableData' :showHeader='isTable ? true : false'
          url='/admin/files' :headers='isTable ? fileTableHeaders : fileHeaders' ref='tableDocumentsRef'
          paginationCounterStyle='files-table__pagination-counter' :formatServerData='formatData'
          :customEmptySlot='true' tableCellClassName='m-0'>
          <template v-slot:placeholder>
            <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified  dashboard__tab-container">
              <li class="nav-item rounded-tabs">
                <a class="nav-link h-100 rounded-tabs" :class='isTable && "active"' href="#solid-rounded-justified-tab1"
                  data-bs-toggle="tab" @click="setTableMode(true)">Таблица</a>
              </li>
              <li class="nav-item">
                <a class="nav-link h-100 rounded-tabs" :class='!isTable && "active"'
                  href="#solid-rounded-justified-tab2" data-bs-toggle="tab" @click="setTableMode(false)">Карточки</a>
              </li>
            </ul>
          </template>
          <template #item-files='{ files }'>
            <div class="file-content">
              <div class="file-body">
                <div class="file-scroll">
                  <div v-if='files && files.length' class="file-content-inner">
                    <h4>Файлы</h4>
                    <div class="files-table__container">
                      <div class="documents__card-file-container" v-for="file in files" :key="file.id"
                        @click='clickHandler(file)' @dblclick='doubleClickHandler(file)' role='button'>
                        <div class="card card-file documents__card-file"
                          :class="{ 'selected-file': file.id === selectedFile }">
                          <div class="card-file-thumb">
                            <file-icon :fileName='file.name' />
                          </div>
                          <div class="card-body">
                            <h6><a href="javascript:;">{{ file.name }}</a></h6>
                            <span>{{ formatSizeUnits(file.size) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class='w-100 d-flex align-items-center justify-content-center'
                    style='min-height: inherit;'>
                    <h1>Список файлов пуст</h1>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #item-actions='file'>
            <div class="text-end">
              <div class="dropdown dropdown-action">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" @click='doubleClickHandler(file)' href="javascript:;">
                    <span class='action-icon-container'>
                      <i class="fa fa-external-link"></i>
                    </span>
                    Открыть
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template #empty-slot>
            <h2>Список файлов пуст</h2>
          </template>
        </core-table>
      </div>
    </div>

    <file-view-modal v-if='data.file' :file='data.file' :onClose='onClosePreview' />
  </div>
</template>
<script setup>
import FilterSearch from "@/components/Filters/ExtendedSearch";
import { formatSizeUnits } from "@/shared/utils/file.ts"
import { reactive, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import {
  companyHeaders,
  fileHeaders,
  fileTableHeaders,
  searchOptions
} from './documents-register.options.ts'
const route = useRoute();
const isFirstLoad = ref(true);
const initialId = route.query.company_id
const tableCompanyRef = ref();
const tableDocumentsRef = ref();
const initialDocumentsTableData = ref({})
const initialCompanyRefData = ref(initialId ? {
  id: initialId
} : {});

const selectedType = reactive({
  type: initialId ? 'id' : 'search_query'
})

const selectedCompany = ref(initialId || null);
const selectCompany = (id) => {
  selectedCompany.value = id;
  updateDocumentsServerOptions({ company_id: id })
}

const isShowAnything = computed(() => {
  if (!initialId) {
    return true;
  }
  return !!initialCompanyRefData.value.id
})

const selectedFile = ref(null);
const updateTableServerOptions = (data) => {
  tableCompanyRef.value.updateServerOptions(data)
}
const updateDocumentsServerOptions = (data) => {
  selectedFile.id = null
  tableDocumentsRef.value.updateServerOptions(data)
}

const afterCompanyLoadhandler = (data) => {
  // Если это не первая загрузка - то больше не используем
  if (!isFirstLoad.value) {
    return;
  }

  if (!initialId) {
    if (data && data.length) {
      selectedCompany.value = data[0].id;
      initialDocumentsTableData.value = { company_id: data[0].id }
    }
    isFirstLoad.value = false;
    return
  }

  if (data && data.length) {
    initialDocumentsTableData.value = { company_id: initialId }
  }

  isFirstLoad.value = false;
}

const doubleClickHandler = (file) => {
  data.file = file;
}

const clickHandler = (file) => {
  selectedFile.value = file.id
}

const data = reactive({
  file: null
})

const onClosePreview = () => {
  data.file = null;
}

const formatData = (data) => {
  if (isTable.value) {
    return data;
  }

  const newData = [{
    files: []
  }];
  data.forEach(item => {
    newData[0].files.push(item)
  })
  return newData
}

const isTable = ref(true);
const isShowTable = ref(true)
const setTableMode = (isShow) => {
  isTable.value = isShow;
  isShowTable.value = false;
  initialDocumentsTableData.value = { company_id: selectedCompany.value }
  setTimeout(() => {
    isShowTable.value = true;
  })
}

</script>
<style>
.company-table__rows {
  --easy-table-body-row-font-color: black !important;
  --easy-table-body-row-background-color: #fff !important;
}
</style>