<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" ref="component" />
  </router-view>
</template>

<script>
export default {
  name: 'RouterChild',
}
</script>
