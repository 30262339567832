<template>
  <!-- Add Employee Modal -->
  <div class="offcanvas offcanvas-end show core-canvas__container " tabindex="-1" id="add_employee"
    aria-labelledby="add_employeeLabel">
    <div class="offcanvas-header">
      <h2 id="add_employeeLabel">Настройка компании</h2>
      <b-button type="button" class="btn-close text-reset" @click='() => onClose(false)'></b-button>
    </div>
    <!-- end offcanvas-header-->
    <div class="offcanvas-body">
      <div class="card-body">
        <core-form url="/me/company" :onSuccess="onSuccess" :initialValidation="resourseManagerCompanySchema"
          v-slot="{ errors, isLoading }" :auth='true' :additionalData='additionalData'>
          <div>
            <h3>Реквизиты компании</h3>
            <div class="row">
              <div class="input-block mb-3">
                <label class="col-form-label">Организационно-правовая форма <span class="text-danger">*</span></label>
                <core-select-input name='company.type' :options="companyOptions" :data='data' :showError='true'
                  :errors='errors' placeholder="Введите организационно-правовую форму" :disabled='!isModeEdit' />
              </div>
            </div>
            <div v-if='data["company.type"]'>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Полное наименование компании</label>
                    <core-text-input name="company.full_name" placeholder="Введите полное наименование компании"
                      :data='data' :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Сокращенное наименование компании <span
                        class="text-danger">*</span></label>
                    <core-text-input name="company.short_name" placeholder="Введите сокращенное наименование компании"
                      :data='data' :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ИНН</label>
                    <core-numeric-input :data='data' name="company.tin" placeholder='123456789012'
                      :mask="isIPRole ? '############' : '##########'" :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>

                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">{{ isIPRole ? 'ОГРН ИП' : 'ОГРН' }}</label>
                    <core-numeric-input :data='data' name="company.ogrn" placeholder='123456789012'
                      :mask="isIPRole ? '###############' : '#############'" :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>

                <div v-if="!isIPRole" class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">КПП</label>
                    <core-numeric-input :data='data' name="company.kpp" placeholder='123456789' mask="#########"
                      :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>

                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ОКПО</label>
                    <core-numeric-input :data='data' name="company.okpo" placeholder='12345678'
                      :mask="isIPRole ? '##########' : '########'" :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">ОКВЭД</label>
                    <core-text-input name="company.okved" placeholder='12.34.56' mask='AAAAAAAA'
                      :stringOptions='okvedOptions' :data='data' :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Номер телефона компании</label>
                    <core-phone-input placeholder="+7 (912) 555 22 22" name='company.phone' :data='data'
                      :showError='true' :errors='errors' maskedValueFieldName='maskedCompanyPhone'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <h3>Юридический адрес компании</h3>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Индекс</label>
                    <core-numeric-input :data='data' name="company.addresses[0].index" placeholder='123456'
                      mask="######" :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Страна</label>
                    <core-text-input name="company.addresses[0].country"
                      placeholder="Введите сокращенное наименование компании" :data='data' :showError='true'
                      :errors='errors' :stringOptions='stringOptions' mask='A' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Город, населенный пункт</label>
                    <core-text-input name="company.addresses[0].city" placeholder="г. Москва" :data='data'
                      :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Улица</label>
                    <core-text-input name="company.addresses[0].street" placeholder="Ильфата Закирова" :data='data'
                      :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дом</label>
                    <core-text-input name="company.addresses[0].house" placeholder="25д" :data='data' :showError='true'
                      :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Корпус</label>
                    <core-text-input name="company.addresses[0].building" placeholder="6" :data='data' :showError='true'
                      :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Квартира, офис</label>
                    <core-text-input name="company.addresses[0].apartment" placeholder="оф. 50" :data='data'
                      :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <core-checkbox-input name='company.is_actual_address_equal_legal' :data='data' :isNumberRequired='true'
                :disabled='!isModeEdit' label='Юридический адрес совпадает с фактическим' />
              <div v-if="!data['company.is_actual_address_equal_legal']" class='row mt-3'>
                <h3>Фактический адрес компании</h3>
                <div class="row">
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Индекс</label>
                      <core-numeric-input :data='data' name="company.addresses[1].index" placeholder='123456'
                        mask="######" :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Страна</label>
                      <core-text-input name="company.addresses[1].country"
                        placeholder="Введите сокращенное наименование компании" :data='data' :showError='true'
                        :errors='errors' :stringOptions='stringOptions' mask='A' :disabled='!isModeEdit' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Город, населенный пункт</label>
                      <core-text-input name="company.addresses[1].city" placeholder="г. Москва" :data='data'
                        :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Улица</label>
                      <core-text-input name="company.addresses[1].street" placeholder="Ильфата Закирова" :data='data'
                        :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Дом</label>
                      <core-text-input name="company.addresses[1].house" placeholder="25д" :data='data'
                        :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Корпус</label>
                      <core-text-input name="company.addresses[1].building" placeholder="6" :data='data'
                        :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-block mb-3">
                      <label class="col-form-label">Квартира, офис</label>
                      <core-text-input name="company.addresses[1].apartment" placeholder="оф. 50" :data='data'
                        :showError='true' :errors='errors' :disabled='!isModeEdit' />
                    </div>
                  </div>
                </div>
              </div>
              <h3>Реквизиты банка</h3>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Полное наименование банка</label>
                    <core-text-input name="company.bank_accounts[0].bank_name"
                      placeholder="Введите полное наименование банка" :data='data' :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">БИК</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].bik" placeholder='044525225'
                      mask="#########" :showError='true' :errors='errors' :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Корреспондентский счет</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].correspondent_account"
                      placeholder='30101810200000000225' mask="####################" :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Расчетный счет</label>
                    <core-numeric-input :data='data' name="company.bank_accounts[0].current_account"
                      placeholder='40702810900000012345' mask="####################" :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
              <h3>Договор</h3>
              <div class="row">
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Номер договора <span class="text-danger">*</span></label>
                    <core-text-input placeholder='XX123456' :data='data' name="company.contracts[0].number"
                      mask='AA######' :stringOptions='agreementNumberOptions' :showError='true' :errors='errors'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дата начала <span class="text-danger">*</span></label>
                    <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].start_date' :data='data'
                      :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedStartDate'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
                <div class="col">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Дата окончания <span class="text-danger">*</span></label>
                    <core-calendar-input inputFormat='dd-MM-yyyy' name='company.contracts[0].end_date' :data='data'
                      :showError='true' :errors='errors' unmaskedValueFieldName='unmaskedEndDate'
                      :disabled='!isModeEdit' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='buttons_container'>
            <core-submit-button v-if='!isModeEdit' className="btn btn-primary submit-btn" type='button'
              text='Редактировать' @click='changeMode(true)' />
            <core-submit-button v-if='isModeEdit' className="btn btn-primary submit-btn" text='Сохранить'
              :isLoading='isLoading' />
            <button v-if='isModeEdit' type='button' class="btn btn-outline-primary submit-btn"
              @click='changeMode(false)'>Отмена</button>
          </div>
        </core-form>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>


  <div class="offcanvas-backdrop fade show" @click='() => onClose(false)'></div>
</template>
<script setup>
import { reactive, onMounted, computed, ref } from 'vue';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { stringOptions, agreementNumberOptions, okvedOptions } from "@/shared/validation/index.ts";
import { resourseManagerCompanySchema } from "@/shared/validation/editResourseCompany.ts";
import { COMPANY_TYPE_ENUM } from "@/shared/validation/enums.ts";
import request from '@/shared/core-api/index.ts';
import { createISOString } from '@/shared/utils/date.ts';
const props = defineProps({
  onClose: Function,
});

const additionalData = {
  _method: 'PUT'
}

const isModeEdit = ref(false);
const changeMode = (modeType) => {
  if (!modeType) {
    Object.assign(data, meta);
  }
  isModeEdit.value = modeType;
}


const data = reactive({})
const meta = reactive({})
if (typeof data['company.contracts[0].start_date'] === 'string') {
  data['company.contracts[0].start_date'] = new Date(data['company.contracts[0].start_date'])
}

if (typeof data['company.contracts[0].end_date'] === 'string') {
  data['company.contracts[0].end_date'] = new Date(data['company.contracts[0].end_date'])
}

let companyOptions = reactive([])

onMounted(async () => {
  try {
    const { company_types } = await getEnums(['company_types'], true);

    company_types && company_types.forEach(item => {
      companyOptions.push(item)
    })

    const { data: responseData } = await request('/me', {
      auth: true
    })

    const company = responseData.company;

    if (company) {
      data['company.type'] = company.type;
      data['company.short_name'] = company.short_name;
      data['company.full_name'] = company.full_name;
      data['company.okved'] = company.okved;
      data['company.phone'] = company.phone;
      data['company.tin'] = company.tin;
      data['company.ogrn'] = company.ogrn;
      data['company.kpp'] = company.kpp;
      data['company.okpo'] = company.okpo;

      data['maskedCompanyPhone'] = company.phone;
      data['company.is_actual_address_equal_legal'] = company.is_actual_address_equal_legal ? 1 : 0;
      data['company.addresses[0].index'] = company.addresses[0] ? company.addresses[0].index : '';
      data['company.addresses[0].country'] = company.addresses[0] ? company.addresses[0].country : '';
      data['company.addresses[0].city'] = company.addresses[0] ? company.addresses[0].city : '';
      data['company.addresses[0].street'] = company.addresses[0] ? company.addresses[0].street : '';
      data['company.addresses[0].house'] = company.addresses[0] ? company.addresses[0].house : '';
      data['company.addresses[0].building'] = company.addresses[0] ? company.addresses[0].building : '';
      data['company.addresses[0].apartment'] = company.addresses[0] ? company.addresses[0].apartment : '';

      data['company.addresses[1].index'] = company.addresses[1] ? company.addresses[1].index : '';
      data['company.addresses[1].country'] = company.addresses[1] ? company.addresses[1].country : '';
      data['company.addresses[1].city'] = company.addresses[1] ? company.addresses[1].city : '';
      data['company.addresses[1].street'] = company.addresses[1] ? company.addresses[1].street : '';
      data['company.addresses[1].house'] = company.addresses[1] ? company.addresses[1].house : '';
      data['company.addresses[1].building'] = company.addresses[1] ? company.addresses[1].building : '';
      data['company.addresses[1].apartment'] = company.addresses[1] ? company.addresses[1].apartment : '';

      data['company.bank_accounts[0].bank_name'] = company.bank_accounts[0] ? company.bank_accounts[0].bank_name : '';
      data['company.bank_accounts[0].bik'] = company.bank_accounts[0] ? company.bank_accounts[0].bik : '';
      data['company.bank_accounts[0].correspondent_account'] = company.bank_accounts[0] ? company.bank_accounts[0].correspondent_account : '';
      data['company.bank_accounts[0].current_account'] = company.bank_accounts[0] ? company.bank_accounts[0].current_account : '';
      const contracts = company.contracts
      if (contracts && contracts.length > 0) {
        data['company.contracts[0].number'] = company.contracts[0].number
        if (company.contracts[0].start_date) {
          data['company.contracts[0].start_date'] = new Date(company.contracts[0].start_date * 1000)
          data.unmaskedStartDate = createISOString(new Date(company.contracts[0].start_date * 1000))
        }

        if (company.contracts[0].end_date) {
          data['company.contracts[0].end_date'] = new Date(company.contracts[0].end_date * 1000)
          data.unmaskedEndDate = createISOString(new Date(company.contracts[0].end_date * 1000))
        }
      }

      Object.assign(meta, data);
    }

  } catch (e) {
    console.error(e)
  }
})

const isIPRole = computed(() => {
  return data['company.type'] === COMPANY_TYPE_ENUM.IP
})

const onSuccess = () => {
  isModeEdit.value = false;
}

</script>
