<template>
  <div class="account-page">
    <success-modal :title='modalTitle' :body='modalBody' :button='modalButton' :onSuccess='modalCallback'
      :onHide='modalCallback' />
    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#success-modal" ref='achorRef'></a>
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <div class="account-logo">
            <router-link :to="getHomeUrl()"><img src="@/assets/img/logo2.png" alt="Dreams Technologies" /></router-link>
          </div>

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Забыли пароль?</h3>
              <p class="account-subtitle">
                Введите свой адрес электронной почты чтобы получить ссылку на сброс пароля
              </p>

              <core-form :initialValidation="schema" url='/forget_password' :onSuccess="onSuccess"
                v-slot="{ errors, isLoading }">
                <div class="input-block mb-4">
                  <core-email-input name='email' :fieldsName='fieldsName' label='Адрес электронной почты'
                    :showLabel='true' :data='data' :showError='true' :errors='errors'
                    placeholder='Введите адрес электронной почты' />
                </div>
                <div class="input-block mb-4 text-center">
                  <core-submit-button className="btn btn-primary auth-button" type="submit" text='
                    Сбросить пароль' :isLoading='isLoading'>
                  </core-submit-button>
                </div>
                <div class="account-footer">
                  <p>Вспомнили пароль? <router-link to="/">Авторизоваться</router-link></p>
                </div>
              </core-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { schema } from "@/shared/validation/forgotPassword.ts";
import { router } from "@/router";
import { getHomeUrl } from '@/shared/utils/index.ts';
const achorRef = ref();
const modalTitle = 'Успешно';
const modalBody = 'Ваш запрос на смену пароля успешно отправлен. Пожалуйста, ожидайте письмо по указанной Вами электронной почте.';
const modalButton = 'Продолжить';
const modalCallback = () => {
  router.push('/')
}

const onSuccess = () => {
  achorRef.value.click()
}

const data = reactive({
  email: ''
});

</script>
