import { useUserStore } from "@/stores/user.ts";
import { ref } from "vue";
import { getCookie } from "@/shared/utils/cookie.ts";
import request from "@/shared/core-api/index.ts";

export const useCounter = () => {
  const counter = ref(null);
  const user = useUserStore();
  const timer = ref(null);
  const getCounter = async (delay) => {
    timer.value = setTimeout(async () => {
      try {
        const token = getCookie("token");
        if (
          token &&
          (user.isAdminRole || user.isITManagerRole || user.isITManagerRole)
        ) {
          const { data } = await request("/counters", {
            auth: true,
          });

          if (user.isAdminRole) {
            counter.value = data && data.new_register_request_count;
          }

          if (user.isITManagerRole || user.isITManagerRole) {
            counter.value = {
              new_tam_response_count: data.new_tam_response_count,
              new_fp_response_count: data.new_fp_response_count,
            };
          }
        }
        getCounter(60000);
      } catch (e) {
        getCounter(60000);
      }
    }, delay);
  };

  return {
    counter,
    timer,
    getCounter,
  };
};
