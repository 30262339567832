<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import LoginLayout from '@/layouts/Login';
import MainLayout from '@/layouts/Default';
import './index.css';

const LOGIN_ROUTES = [
  'login',
  'register',
  'forgot',
  'change-password',
  'forgot-password',
  'reset-password',
  'not-found'
]

export default {
  components: {
    LoginLayout,
    MainLayout,
  },
  provide() {
    return {
      currentRoute: computed(() => this.route),
      loginRoutes: () => LOGIN_ROUTES,
    }
  },
  /*  data() {
      return {
        layout: ''
      };
    },*/
  computed: {
    route() {
      return useRoute();
    },
    layout() {
      return LOGIN_ROUTES.includes(this.route.name)
        ? 'login-layout'
        : 'main-layout'
    },
  },

};
</script>
<style lang="scss">
#app {
  height: 100%
}

.sinkos-style-4 {
  display: flex;
  justify-content: space-between;
}
</style>
