<template>
  <fix-price-request-card-info v-if='isShowInfoCard.isShow' :onClose='showCard' :id='isShowInfoCard.id' />
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' />
  <div class="content container-fluid">
    <breadcrumb-header />

    <!-- Search Filter -->
    <resource-fix-price-requests-filters :filters='filters' :mode='tableMode' :resetFilters='resetFilters' />
    <!-- /Search Filter -->
    <div class="row">
      <div class="col-md-12">
        <core-table v-if='isShowTable' :url='tableOptions[tableMode].url' ref='tableRef'
          :headers='tableOptions[tableMode].headers' :minHeight='300' :initialData='filters' :initialPage='initialPage'
          :isSaveFilters='true'>
          <template v-slot:placeholder>
            <div class="tab-bar">
              <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" :class='tableMode === ShowTableModeEnum.Requests && "active"'
                    href="#solid-rounded-justified-tab1" data-bs-toggle="tab"
                    :aria-selected='tableMode === ShowTableModeEnum.Requests'
                    @click='changeTableMode(ShowTableModeEnum.Requests)'>Запросы</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link h-100 rounded-tabs" :class='tableMode === ShowTableModeEnum.Resources && "active"'
                    href="#solid-rounded-justified-tab2" data-bs-toggle="tab"
                    :aria-selected='tableMode === ShowTableModeEnum.Resources'
                    @click='changeTableMode(ShowTableModeEnum.Resources)'>Мои отклики</a>
                </li>
              </ul>
            </div>
          </template>
          <template #item-status="{ status }">
            <div :class='getStatusClass(status)' class='p-2 text-center'>
              {{ status }}
            </div>
          </template>
          <template #item-project_name="{ project }">
            <div>
              {{ project.name }}
            </div>
          </template>
          <template #item-created_at='{ created_at }'>
            <div>
              {{ fromTimestampToISOString(created_at && created_at * 1000) }}
            </div>
          </template>
          <template #item-kp_date='{ kp_date }'>
            <div>
              {{ fromTimestampToISOString(kp_date && kp_date * 1000) }}
            </div>
          </template>
          <template #item-cost="{ cost }">
            {{ cost && Number(cost).toLocaleString() }} руб
          </template>
          <template #item-actions="{ files, id, fp_request_id }">
            <div class="text-end">
              <div class="dropdown dropdown-action">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item"
                    @click='showCard(true, tableMode === ShowTableModeEnum.Requests ? id : fp_request_id)'>
                    <span class='action-icon-container'>
                      <i class="fa fa-external-link"></i>
                    </span>
                    Открыть
                  </a>
                  <a v-if='tableMode === ShowTableModeEnum.Resources' class="dropdown-item" @click='openFile(files[0])'>
                    <span class='action-icon-container'>
                      <i class="fa-regular fa-file-pdf"></i>
                    </span>
                    Просмотреть КП
                  </a>
                </div>
              </div>
            </div>
          </template>
        </core-table>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>
<script setup>
import { ref } from 'vue'
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { getStatusClass, ShowTableModeEnum } from '@/pages/requests/index.ts'
import { tableOptions } from "./requests-fix-price.options.ts";
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
import { useResourceFixPriceRequestsFilters } from '@/widgets/filters/index.ts';
const { selectedFile, openFile, closeFile } = useFileModal();

const isShowTable = ref(true);
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

const {
  filters,
  initialPage,
  resetFilters,
  changeTableMode,
  tableMode
} = useResourceFixPriceRequestsFilters(updateServerOptions, isShowTable);

const isShowInfoCard = ref({
  isShow: false,
  id: null
});

const showCard = (isShow, id) => {
  isShowInfoCard.value.isShow = isShow;
  isShowInfoCard.value.id = id ? id : null;

  if (!isShow) {
    tableRef.value.updateServerOptions()
  }
}
</script>
<style scoped>
.tab-bar {
  @apply w-max;
}

:deep(.nav-item) {
  flex-basis: auto !important;
}
</style>
