import request from "@/shared/core-api/index.ts";
import { queryInterceptor } from "@/shared/utils/queryInterceptor.ts";
import { router } from "@/router";

const isCheckCompetenciesForResourseManager = async (queryState) => {
  try {
    if (queryState.isLoading) {
      return;
    }
    queryState.isLoading = true;
    const { data } = await request("/me/technical_fields", {
      auth: true,
    });

    queryState.isLoading = false;
    if (data.length) {
      return true;
    } else {
      router.push("/resource-competencies");
    }
  } catch (e) {
    console.error(e);
    queryState.isLoading = false;
  }
};

export const wrappedIsCheckCompetenciesForResourseManager = queryInterceptor(
  isCheckCompetenciesForResourseManager
);
