<template>
  <add-entity-area v-if="isShowAddEntity" :url="schema[currentState].url" :title="schema[currentState].addEntityText"
    :label="schema[currentState].entityLabel" :onClose="schema[currentState].show" :id="currentId" />
  <add-requirement v-if="isShowRequirment" :url="schema[currentState].url" :title="schema[currentState].addEntityText"
    :label="schema[currentState].entityLabel" :onClose="schema[currentState].show" :techOptions="techOptions"
    :id="currentId" />
  <div class="content container-fluid">
    <breadcrumb-header :handleTitle='user.isAdminRole ? "" : "Справочники / Компетенции"' />

    <filter-component v-if="schema[currentState].showFilter && isShowTable"
      :updateServerOptions="updateServerOptions" />
    <div class="competencies__tables-container">
      <div class="competencies__tabs-table">
        <div class="file-pro-list competencies__tabs-table-container">
          <div class="file-scroll">
            <ul class="file-menu">
              <li v-for="tab in tabs" :key="tab.key" :class="{ active: tab.key === currentState }"
                @click="setTab(tab.key)">
                <a href="javascript:;">{{ tab.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row competencies__competencies-table">
        <div class="col-md-12">
          <core-table v-if='isShowTable' :url="schema[currentState].url" :headers="schema[currentState].headers"
            ref="tableRef">
            <template v-slot:placeholder v-if='user.isAdminRole'>
              <div class="mb-3">
                <core-submit-button :text="`+ ${schema[currentState].addEntityText}`" :isLoading="isDataLoading"
                  className="entity-button" @click="schema[currentState].show(true)" />
              </div>
            </template>
            <template #item-technical_field_name="{ technical_field }">
              <div>{{ technical_field && technical_field.name }}</div>
            </template>
            <template #item-actions="{ id }">
              <div class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" @click="schema[currentState].show(true, id)"><i
                        class="fa-solid fa-pencil m-r-5"></i> Редактировать</a>
                    <a class="dropdown-item" @click="deleteEntity(id)"><i class="fa-regular fa-trash-can m-r-5"></i>
                      Удалить</a>
                  </div>
                </div>
              </div>
            </template>
          </core-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import request from '@/shared/core-api/index.ts';
import { useToast } from 'vue-toast-notification';
import FilterComponent from '@/components/Filters/Tech';
import { getEnums } from '@/shared/core-api/getEnums.ts';
import { useUserStore } from '@/stores/user.ts';
import { States, tabs } from './competencies.options.ts';
const $toast = useToast();
const isShowTable = ref(true);
const user = useUserStore();
const techOptions = ref([]);
const isDataLoading = ref(false)
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data);
};

const currentState = ref(States.TECHINAL_FIELD);
const isShowAddEntity = ref(false);
const currentId = ref(null)
const showEntity = (isShow, id) => {
  isShowAddEntity.value = isShow;
  if (id || id !== 'undefined') {
    currentId.value = id;
  }
  if (!isShow) {
    updateServerOptions()
  }
}

const isShowRequirment = ref(false);
const showRequirment = async (isShow, id) => {
  if (isShow) {
    try {
      isDataLoading.value = true;
      const { competencies_technical_fields } = await getEnums('competencies_technical_fields', {
        auth: true
      })

      if (!competencies_technical_fields || competencies_technical_fields.length === 0) {
        $toast.open({
          type: 'warning',
          message: `
          <p class="py-0 my-0">Добавьте компетенцию</p>
          <p class="py-0 my-0">в техническую область</p>`,
          duration: 0,
          position: 'bottom-left'
        })
        isDataLoading.value = false;
        return;
      }

      techOptions.value = competencies_technical_fields
      isDataLoading.value = false
    } catch (e) {
      isDataLoading.value = false
    }
  } else {
    techOptions.value = []
  }

  isShowRequirment.value = isShow
  if (id || id !== 'undefined') {
    currentId.value = id;
  }
  if (!isShow) {
    updateServerOptions()
  }
}

const deleteEntity = async (id) => {
  try {
    await request(`${schema[currentState.value].url}/${id}`, {
      method: 'DELETE',
      auth: true
    })
    updateServerOptions()
  } catch (e) {
    console.error(e)
  }
}

const schema = {
  technical_fields: {
    url: `${user.isAdminRole ? "/admin" : ""}/competencies/technical_fields`,
    addEntityText: "Добавить техническую область",
    entityLabel: "технической области",
    show: showEntity,
    showFilter: false,
    headers: user.isAdminRole ? [
      {
        text: "Техническая область",
        value: "name",
        custom: false,
        sortable: true,
      },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ] : [
      {
        text: "Техническая область",
        value: "name",
        custom: false,
        sortable: true,
      },
    ],
  },
  project_roles: {
    url: `${user.isAdminRole ? "/admin" : ""}/competencies/project_roles`,
    addEntityText: "Добавить проектную роль",
    entityLabel: "проектной роли",
    show: showEntity,
    showFilter: false,
    headers: user.isAdminRole ? [
      { text: "Проектная роль", value: "name", custom: false, sortable: true },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ] : [
      { text: "Проектная роль", value: "name", custom: false, sortable: true },
    ],
  },
  grades: {
    url: `${user.isAdminRole ? "/admin" : ""}/competencies/grades`,
    addEntityText: "Добавить грейд",
    entityLabel: "грейда",
    show: showEntity,
    showFilter: false,
    headers: user.isAdminRole ? [
      { text: "Грейд", value: "name", custom: false, sortable: true },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ] : [
      { text: "Грейд", value: "name", custom: false, sortable: true },
    ],
  },
  tf_requirements: {
    url: `${user.isAdminRole ? "/admin" : ""}/competencies/tf_requirements`,
    addEntityText: "Добавить требование",
    entityLabel: "требование",
    show: showRequirment,
    showFilter: true,
    headers: user.isAdminRole ? [
      { text: "Требование", value: "text", custom: false, sortable: true },
      { text: "Техническая область", value: "technical_field_name", custom: true, sortable: true },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ] : [
      { text: "Требование", value: "text", custom: false, sortable: true },
      { text: "Техническая область", value: "technical_field_name", custom: true, sortable: true },
    ],
  },
  tf_duties: {
    url: `${user.isAdminRole ? "/admin" : ""}/competencies/tf_duties`,
    addEntityText: "Добавить обязанность",
    entityLabel: "обязанность",
    show: showRequirment,
    showFilter: true,
    headers: user.isAdminRole ? [
      { text: "Обязанность", value: "text", custom: false, sortable: true },
      { text: "Техническая область", value: "technical_field_name", custom: true, sortable: true },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ] : [
      { text: "Обязанность", value: "text", custom: false, sortable: true },
      { text: "Техническая область", value: "technical_field_name", custom: true, sortable: true },
    ]
  },
};

const setTab = (key) => {
  isShowTable.value = false;
  currentState.value = key;
  setTimeout(() => {
    isShowTable.value = true
  })
};

</script>
<style scoped lang="scss">
.content.container-fluid {
  @apply h-full w-full;
}

.file-sidebar {
  @apply h-full w-[292px];
}

.main {
  display: -webkit-inline-box;
  gap: 30px;
  @apply h-full w-full;
}

.file-pro-list {
  background-color: #ffffff;
  padding: 15px;
  height: calc(100% - 65px);
  @apply border-gray-300 border border-solid pt-8;

  & li a {
    color: #333333;
    display: inline-block;
    width: 100%;
    text-transform: capitalize;
    padding: 10px 15px;
    transition: all 0.2s ease;
    @apply hover:bg-gray-300;
  }

  & .active {
    @apply bg-gray-200;
  }
}
</style>
