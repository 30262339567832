<template>
  <!-- Add Employee Modal -->
  <div id="add_requirement" class="modal custom-modal fade show d-block" ref='modalWindowRef' @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click='onClose(false, null)'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <core-form :url='id ? `${url}/${id}` : url' v-slot='{ errors, isLoading }' :auth="true" :onSuccess="onSuccess"
            :initialValidation="requirementSchema" :additionalData="id ? additionalData : null">
            <div class="input-block mb-3">
              <label class="col-form-label">Техническая область <span class="text-danger">*</span></label>
              <core-select-input :disabled="isDataLoading" :data="data" name="competence_tf_id" displayName="name"
                :showError="true" valueProp='id' :options="techOptions" placeholder="Выберите обозначение"
                :errors="{ errors }" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Опишите {{ label }} <span class="text-danger">*</span></label>
              <core-textarea-input :disabled="isDataLoading" :data="data" name='text' :rows="15" :showError="true"
                :errors="errors" />
            </div>

            <div class='buttons_container'>
              <core-submit-button :isLoading="isLoading" :disabled="!(data.text && data.competence_tf_id)"
                className="btn btn-primary submit-btn" text="Сохранить" />
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>

<script setup>
import request from '@/shared/core-api/index.ts';
import { reactive, onMounted, ref } from 'vue';
import { requirementSchema } from "@/shared/validation/addRequirement.ts";

const isDataLoading = ref(false)
const modalWindowRef = ref();

const props = defineProps({
  url: String,
  title: String,
  label: String,
  onClose: Function,
  id: String,
  techOptions: Array
})

const additionalData = {
  _method: 'PUT'
}

const data = reactive({
  competence_tf_id: '',
  text: ''
})

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false, null)
  }
}

const onSuccess = () => {
  props.onClose(false, null);
}

onMounted(async () => {
  if (props.id) {
    try {
      isDataLoading.value = true;
      const response = await request(`${props.url}/${props.id}`, {
        auth: true
      })

      data.text = response.data.text;
      data.competence_tf_id = response.data.technical_field.id
      isDataLoading.value = false
    } catch (e) {
      isDataLoading.value = false
    }
  }
})

</script>
