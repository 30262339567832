<template>
  <div class="form-check">
    <Field :name='name' v-model="data[name]" :standalone="isIgnore">
      <input type="checkbox" class="form-check-input" :id="name" :disabled='disabled' :checked='data[name]'
        @change='onChange' />
      <label class="form-check-label" :for="name">{{ label }}
      </label>
    </Field>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
const props = defineProps({
  data: Object,
  name: String,
  isNumberRequired: Boolean,
  label: {
    type: String, default: ''
  },
  disabled: {
    type: Boolean, default: false
  },
  isIgnore: {
    type:Boolean, default: false,
  }
})

const onChange = (event) => {
  const checked = event.target.checked;
  if (props.isNumberRequired) {
    props.data[props.name] = checked ? 1 : 0
  } else {
    props.data[props.name] = checked
  }
}

</script>