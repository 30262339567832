<template>
  <div class="content container-fluid">
    <breadcrumb-header />
    <competencies-alert v-if='isShowAlert' :onClose='setIsShowAlert'></competencies-alert>
    <competencies-modal v-if='isShowModal' :onClose='setIsShowModal'></competencies-modal>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card profile-box flex-fill">
          <div class="competencies__card">
            <div class='competencies__filters-container'>
              <perfect-scrollbar>
                <div class='competencies__filters-inner-container'>
                  <div v-for="(item, index) in technicalFields" :key="item.id" :class="index === technicalFields.length - 1 ? 'pb-3' : ''">
                    <span @click='setSelectedFilter(item.technical_field.id)'
                      class="badge rounded-pill badge-soft-info competencies__badge"
                      :class='item.technical_field.id === selectedFilter && "competencies__badge_active"'>
                      {{ item.technical_field.name }}
                    </span>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div>
              <a href="javascript:;" class="edit-icon" @click="setIsShowModal(true)">
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <filter-component :updateServerOptions='updateServerOptions'></filter-component>
      <core-table v-if='technicalFields.length' url='/me/get_tf_requirements' :headers='headers' ref='tableRef'
        :initialData='initialTFRefData'>
        <template #item-technical_field_name='{ technical_field }'>
          <div>
            {{ technical_field && technical_field.name }}
          </div>
        </template>
      </core-table>
    </div>


  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import FilterComponent from '@/components/Filters/Search';
import request from '@/shared/core-api/index.ts';
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

// Модалка для выбора компетенций
const isShowModal = ref(false);
const setIsShowModal = (isShow) => {
  isShowModal.value = isShow;

  if (!isShow) {
    getTechnicalField();
  }
}

const isShowAlert = ref(false);
const setIsShowAlert = (isShow) => {
  isShowAlert.value = isShow;
  if (!isShow) {
    setIsShowModal(true);
  }
}

const technicalFields = ref([]);
const filters = ref('');
const selectedFilter = ref(null);

const setSelectedFilter = (id) => {
  if (id === selectedFilter.value) {
    selectedFilter.value = null
  } else {
    selectedFilter.value = id
  }

  updateFilters();
}

const getTechnicalField = async () => {
  try {
    let { data } = await request('/me/technical_fields', {
      auth: true
    });

    if (!data.length) {
      setIsShowAlert(true);
      return;
    }
    const result = formatDataForRequest(data);
    initialTFRefData.value.competence_tf_ids = result;
    filters.value = result
    technicalFields.value = data;
  } catch (e) {

  }
}

const updateFilters = () => {
  if (selectedFilter.value) {
    updateServerOptions({ competence_tf_ids: selectedFilter.value })
  } else {
    updateServerOptions({ competence_tf_ids: filters.value })
  }
}

const initialTFRefData = ref({
  competence_tf_ids: ''
});

onMounted(() => {
  try {
    getTechnicalField();
  } catch (e) {

  }
});

const headers = [
  { text: "Требование", value: "text", sortable: true, custom: false },
  { text: "Техническая область", value: "technical_field_name", sortable: true, custom: true },
];

const formatDataForRequest = (value) => {
  let arr = [];
  value.forEach((tf) => {
    arr.push(tf.technical_field.id);
  })

  return arr.join(',');
}
</script>
