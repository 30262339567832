<template>
  <label v-if='showLabel' class="col-form-label">
    {{ label }}
    <span v-if='required' class="text-danger">*</span>
  </label>
  <Field :name="name" v-model="data[name]" inputmode="numeric" class="form-control" :placeholder='placeholder'
    :data-maska="mask" v-maska:[maskOptions] :class="{ 'is-invalid': showError && errors[name] }"
    :validateOnModelUpdate='false' :disabled='disabled' :validateOnChange='false' :validateOnBlur='false' />
  <div class="invalid-feedback">{{ showError && errors[name] }}</div>
  <div class="emailshow text-danger" :id="name"></div>
</template>
<script setup>
import { Field } from "vee-validate";
import { vMaska } from "maska";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount, watch } from 'vue';
const props = defineProps({
  data: Object,
  placeholder: String,
  disabled: Boolean,
  maskOptions: Object,
  showError: Boolean,
  errors: Object,
  name: String,
  mask: String,
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
})

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

if (props.fieldsName && props.label && props.name) {
  watch(
    () => props.label,
    (value, oldValue) => {
      if (oldValue) {
        removeFieldName(props.fieldsName, props.name, oldValue)
      }
      if (value) {
        addFieldName(props.fieldsName, props.name, value)
      }
    },
    { deep: true }
  );
}
</script>