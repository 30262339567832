<template>
  <!-- Add Employee Modal -->
  <div class="modal custom-modal fade show d-block" ref='modalWindowRef' @mousedown='localOnClose'>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="onClose(false, null)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <core-form :url='id ? `${url}/${id}` : url' :auth='true' :onSuccess='onSuccess' v-slot='{ errors, isLoading }'
            :additionalData="id ? additionalData : null" :initialValidation="entitySchema">
            <div class="input-block mb-3">
              <label class="col-form-label">Введите название {{ label }}<span class="text-danger">*</span></label>
              <core-text-input name='name' :disabled="isDataLoading" :data='data' :showError='true' :errors='errors' />
            </div>

            <div class='buttons_container'>
              <core-submit-button :isLoading="isLoading" className="btn btn-primary submit-btn" :disabled="disabled"
                text="Сохранить" />
            </div>
          </core-form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>
<script setup>
import request from '@/shared/core-api/index.ts';
import { reactive, onMounted, ref, computed } from 'vue';
import { entitySchema } from "@/shared/validation/addEntity.ts";
const isDataLoading = ref(false)
const modalWindowRef = ref();

const props = defineProps({
  url: String,
  title: String,
  label: String,
  onClose: Function,
  id: String,
})

const additionalData = {
  _method: 'PUT'
}

const data = reactive({
  name: ''
})

const loadedData = ref(null)
const disabled = computed(() => {
  if (props.id) {
    return !data.name || (data.name === loadedData.value)
  } else {
    return !data.name
  }
})

onMounted(async () => {
  if (props.id) {
    try {
      isDataLoading.value = true;
      const response = await request(`${props.url}/${props.id}`, {
        auth: true
      })

      data.name = response.data.name;
      loadedData.value = response.data.name;
      isDataLoading.value = false
    } catch (e) {
      isDataLoading.value = false
    }
  }
})

const localOnClose = (event) => {
  if (event.target === modalWindowRef.value) {
    props.onClose(false, null)
  }
}

const onSuccess = () => {
  props.onClose(false, null);
}

</script>