import * as Yup from "yup";
import { COMPANY_TYPE_ENUM, USER_ROLE_ENUM } from "./enums.ts";
import { tokens } from "maska";

const emailYupValidationRegExp = /(.+)@(.+)\.(.+)/i;

export const FieldsValidation = {
  email: Yup.string()
    .required("Поле не может быть пустым")
    .matches(
      emailYupValidationRegExp,
      "Адрес электронной почты введен не верно"
    ),
  emailNotReq: Yup.string()
    .transform((v, o) => (o === "" ? null : v))
    .notRequired()
    .matches(
      emailYupValidationRegExp,
      "Адрес электронной почты введен не верно"
    ),
  password: Yup.string()
    .required("Поле не может быть пустым")
    .min(6, "Пароль должен содержать минимум 6 символов")
    .max(6, "Пароль должен содержать максимум 6 символов"),
  phoneNumber: Yup.string()
    .required("Поле не может быть пустым")
    .min(11, "Номер телефона некорректен")
    .max(11, "Номер телефона некорректен"),
  phoneNumberNotReq: Yup.string()
    .transform((v, o) => (o === "" ? null : v))
    .notRequired()
    .min(11, "Номер телефона некорректен")
    .max(11, "Номер телефона некорректен"),
  companyName: Yup.string().required("Укажите название компании"),
  files: Yup.array()
    .required("Поле не может быть пустым")
    .min(1, "Поле не может быть пустым"),
  notEmptyString: Yup.string().required("Поле не может быть пустым"),
  notEmptyNumber: Yup.number().required("Поле не может быть пустым"),
  correspondentAccount: Yup.string()
    .nullable()
    .test("len", "Введите 20 цифр", (val) => !val || val.length === 20),
  tin: Yup.string()
    .notRequired()
    .nullable()
    .test("len", "Введите 12 цифр", (val, validationContext) => {
      const type = validationContext.parent.type;
      const { createError } = validationContext;

      if (!val) {
        return true;
      }
      if (type === COMPANY_TYPE_ENUM.IP) {
        return val.length === 12
          ? true
          : createError({ message: "Введите 12 цифр" });
      } else {
        return val.length === 10
          ? true
          : createError({ message: "Введите 10 цифр" });
      }
    }),
  okpo: Yup.string()
    .nullable()
    .test("len", "Введите 10 цифр", (val, validationContext) => {
      const type = validationContext.parent.type;
      const { createError } = validationContext;

      if (!val) {
        return true;
      }
      if (type === COMPANY_TYPE_ENUM.IP) {
        return val.length === 10
          ? true
          : createError({ message: "Введите 10 цифр" });
      } else {
        return val.length === 8
          ? true
          : createError({ message: "Введите 8 цифр" });
      }
    }),
  ogrn: Yup.string()
    .notRequired()
    .test("len", "Введите 15 цифр", (val, validationContext) => {
      const type = validationContext.parent.type;
      const { createError } = validationContext;

      if (!val) {
        return true;
      }
      if (type === COMPANY_TYPE_ENUM.IP) {
        return val.length === 15
          ? true
          : createError({ message: "Введите 15 цифр" });
      } else {
        return val.length === 13
          ? true
          : createError({ message: "Введите 13 цифр" });
      }
    }),
  kpp: Yup.string()
    .nullable()
    .test("len", "Введите 9 цифр", (val) => !val || val.length === 9),
  okved: Yup.string()
    .nullable()
    .test(
      "len",
      "Введите от 2 до 8 цифр",
      (val) => !val || (2 <= val.length && val.length <= 8)
    ),
  index: Yup.string()
    .nullable()
    .test("len", "Введите 6 цифр", (val) => !val || val.length === 6),
  bik: Yup.string()
    .nullable()
    .test("len", "Введите 9 цифр", (val) => !val || val.length === 9),
  correspondent_account: Yup.string()
    .nullable()
    .test("len", "Введите 20 цифр", (val) => !val || val.length === 20),
  textarea: Yup.string()
    .required("Поле не может быть пустым")
    .max(255, "Поле может содержать максимально 255 символов"),
  agreementNumber: Yup.string()
    .required("Поле не может быть пустым")
    .test("len", "Введите 8 символов", (val) => !val || val.length === 8),
  agreementNumberNotReq: Yup.string()
    .nullable()
    .test("len", "Введите 8 символов", (val) => !val || val.length === 8),
  requiredString: Yup.string()
    .required("Поле не может быть пустым")
    .max(255, "Поле может содержать максимально 255 символов"),
  notRequiredStringWithLimit: Yup.string()
    .nullable()
    .test(
      "len",
      "Поле может содержать максимально 255 символов",
      (val) => !val || val.length <= 255
    ),
  projectDescription: Yup.string()
    .required("Поле не может быть пустым")
    .max(65535, "Поле может содержать максимально 65535 символов"),
};

const emailLoginRegExp = new RegExp(/[0-9A-Za-z\-._]/);
const latSymbolsRegExp = new RegExp(/[A-Za-z]/);
const stringRegExp = new RegExp(/[\p{L}-]/u);
const numberOrDot = new RegExp(/[0-9.]/);

export const emailOptions = {
  tokens: {
    A: {
      pattern: emailLoginRegExp,
      multiple: true,
    },
    B: {
      pattern: /[@]/,
      multiple: false,
    },
    C: {
      pattern: latSymbolsRegExp,
      multiple: true,
    },
    D: {
      pattern: /[.]/,
      multiple: false,
    },
    E: {
      pattern: latSymbolsRegExp,
      multiple: true,
    },
  },
};

export const stringOptions = {
  tokens: {
    A: {
      pattern: stringRegExp,
      multiple: true,
    },
  },
};

export const agreementNumberOptions = {
  tokens: {
    A: {
      pattern: latSymbolsRegExp,
      multiple: false,
      transform: (chr) => chr.toUpperCase(),
    },
  },
};

export const okvedOptions = {
  tokens: {
    A: {
      pattern: numberOrDot,
      multiple: false,
    },
  },
};

export const numberStringOptions = {
  tokens: {
    A: {
      pattern: /[0-9]/,
      multiple: true,
    },
  },
};
