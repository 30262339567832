<template>
  <img :class='className' :src='src' />
</template>
<script setup>
import { getFileExt } from '@/shared/utils/file.ts'
import csvIcon from '@/assets/img/fileIcons/csv_icon.svg';
import docLogo from '@/assets/img/fileIcons/doc_icon.svg';
import docxLogo from '@/assets/img/fileIcons/docx_icon.svg';
import jpegLogo from '@/assets/img/fileIcons/jpeg_icon.svg';
import pdfLogo from '@/assets/img/fileIcons/pdf_icon.svg';
import pngLogo from '@/assets/img/fileIcons/png_icon.svg';
import pptLogo from '@/assets/img/fileIcons/ppt_icon.svg';
import rtfLogo from '@/assets/img/fileIcons/rtf_icon.svg';
import tiffLogo from '@/assets/img/fileIcons/tiff_icon.svg';
import txtLogo from '@/assets/img/fileIcons/txt_icon.svg';
import xlsLogo from '@/assets/img/fileIcons/xls_icon.svg';

const iconsSet = {
  csv: csvIcon,
  doc: docLogo,
  docx: docxLogo,
  jpeg: jpegLogo,
  pdf: pdfLogo,
  png: pngLogo,
  ppt: pptLogo,
  rtf: rtfLogo,
  tiff: tiffLogo,
  txt: txtLogo,
  xls: xlsLogo
}

const props = defineProps({
  fileName: String,
  className: String
})

const ext = getFileExt(props.fileName)
const src = iconsSet[ext] ? iconsSet[ext] : iconsSet.pdf
</script>