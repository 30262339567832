<template>
  <div class="content">
    <breadcrumb-header />

    <div class="row">
      <div class="col-md-12">
        <core-table url='/notifications' :headers='headers' :showHeader='false' ref='tableRef'
          :formatServerData='formatData' :customEmptySlot='true' tableCellClassName='notification-table__cell'>
          <template v-slot:placeholder>
            <core-submit-button className="btn btn-primary submit-btn" text='Очистить' type='button'
              :isLoading='isLoading' :onClick='clearNotifications' />
          </template>
          <template #item-notifications='{ notifications }'>
            <div class="notification">
              <div class="notification-box">
                <ul class="notification-list">
                  <li v-for="(notification, index) in notifications" :key="index">
                    <div class="notification-content">
                      <div class="timeline-content">
                        <div class="text-black mb-1">{{ notification && notification.data && notification.data.message }}</div>
                        <span class="time">{{ fromTimestampToISOString(notification.created_at * 1000) }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </core-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import request from '@/shared/core-api/index.ts';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
const tableRef = ref();
const isLoading = ref(false);
const headers = [
  { text: "", value: "notifications", sortable: false, custom: true },
];

const formatData = (data) => {
  if (data.length === 0) {
    return data;
  }
  const newData = [{
    notifications: []
  }];
  data.forEach(item => {
    newData[0].notifications.push(item)
  })
  return newData
}

const clearNotifications = async () => {
  try {
    isLoading.value = true;
    await request('/notifications/delete', {
      method: 'POST',
      auth: true
    });
    tableRef.value.updateServerOptions();
    isLoading.value = false;
  } catch (e) {
    isLoading.value = false;
  }
}

</script>
