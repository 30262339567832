<template>
  <dialog-modal :title='dialogOptions.title' :body='dialogOptions.body' :onSuccessButton='dialogOptions.onSuccessButton'
    :onCancelButton='dialogOptions.onCancelButton' :onSuccess='dialogOnSuccess' />
  <a data-bs-toggle="modal" data-bs-target="#warning-alert-modal" ref='dialogModalRef'></a>
  <div class="offcanvas offcanvas-end core-canvas__card show" tabindex="-1" id="create_project"
    aria-labelledby="create_projectLabel">
    <modal-header :title='title' :isShowSeparator='true' :onClose='() => onCloseHandler(false)' />

    <div class="offcanvas-body">
      <div class="card-body">
        <core-form v-if='!isReadOnly' :url="url" v-slot='{ errors, isLoading }' :initialValidation='projectSchema'
          :onSuccess='onSuccess' :auth='true' :additionalData="id ? additionalData : null">
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Название<span class="text-danger">*</span></label>
                <core-text-input :data='data' :errors='errors' name='name' :showError='true'
                  placeholder='Введите название' :disabled='isLoadingref' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Бюджет <span class="text-danger">*</span></label>
                <core-numeric-input :data='data' :errors='errors' name='budget' :showError='true'
                  placeholder='Введите бюджет проекта' :disabled='isLoadingref' mask="A"
                  :maskOptions='numberStringOptions' />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">ID проекта <span class="text-danger">*</span></label>
                <core-text-input placeholder='XX123456' :data='data' name="number" mask='AA######'
                  :stringOptions='agreementNumberOptions' :showError='true' :errors='errors' :disabled='isLoadingref' />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <label class="col-form-label">Описание <span class="text-danger">*</span></label>
                <core-textarea-input name='description' :data='data' :errors='errors' className='file-table'
                  :showError='true' :disabled='isLoadingref' />
              </div>
            </div>
          </div>

          <div class='buttons_container'>
            <core-submit-button :isLoading="isLoading" className="btn btn-primary submit-btn" text='Сохранить'
              :disabled='!isChanged || isLoadingref' />
            <button class="btn btn-outline-primary submit-btn" type='button'
              @click='onCloseHandler(false)'>Отмена</button>
          </div>
        </core-form>
        <div v-else>
          <div class="row">
            <div class="col project__text-container">
              <div class="input-block mb-3">
                <h3 class='project__label'>Название</h3>
                <p class='project__text'>{{ data.name }}</p>
              </div>
            </div>
          </div>
          <div v-if='!user.isPurchasingManagerRole' class="row">
            <div class="col project__text-container">
              <div class="input-block mb-3">
                <h3 class='project__label'>Бюджет </h3>
                <p class='project__text'>{{ data.budget && Number(data.budget).toLocaleString() }} руб</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col project__text-container">
              <div class="input-block mb-3">
                <h3 class='project__label'>ID проекта </h3>
                <p class='project__text'>{{ data.number }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="input-block mb-3">
                <h3 class='project__label'>Описание </h3>
                <p class='project__text project__textarea'>{{ data.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show" @click='onCloseHandler(false)'></div>
</template>
<script setup>
import { numberStringOptions } from "@/shared/validation/index.ts";
import request from '@/shared/core-api/index.ts';
import { onMounted, reactive, ref, computed } from 'vue';
import { agreementNumberOptions } from "@/shared/validation/index.ts";
import { projectSchema } from "@/shared/validation/project.ts";
import { useUserStore } from '@/stores/user.ts';
import { dialogOptions } from './create-project-modal.options.ts'
const user = useUserStore()
const props = defineProps({
  id: {
    type: Number, default: null,
  },
  onClose: Function,
  isReadOnly: {
    type: Boolean, default: false
  }
});

const dialogModalRef = ref()

const dialogOnSuccess = () => {
  props.onClose(false, null);
}

const onCloseHandler = () => {
  if (!props.isReadOnly && !props.id) {
    dialogModalRef.value.click();
    return;
  }

  props.onClose(false, null);
}

const title = ref(props.id ? '' : 'Новый проект')

const data = reactive({
  name: '',
  budget: '',
  number: '',
  description: ''
})

const isLoadingref = ref(false);
const url = props.id ? `/projects/${props.id}` : '/projects'

const responseDataRef = ref({})
const isChanged = computed(() => {
  if (!props.id) {
    return true
  }

  const response = responseDataRef.value
  if (
    data.name === response.name &&
    data.budget === response.budget &&
    data.number === response.number &&
    data.description === response.description
  ) {
    return false
  }

  return true;
})

onMounted(async () => {
  try {
    isLoadingref.value = true;
    if (props.id) {
      const { data: responseData } = await request(url, {
        auth: true
      });

      responseDataRef.value = responseData
      Object.assign(data, responseData);
      title.value = `${responseData.number} ${responseData.name}`
    }
    isLoadingref.value = false;
  } catch (e) {

  }
});

const additionalData = {
  _method: 'PUT'
}

const onSuccess = () => {
  props.onClose();
}
</script>
