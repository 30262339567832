import { getEnums } from "@/shared/core-api/getEnums.ts";
import { computed, reactive, ref } from "vue";
import request from "@/shared/core-api/index.ts";
import { ModeEnums } from "@/pages/requests/index.ts";
import { RequestTypeEnum } from "./requestEnums.ts";

export function useRequest({
  setAdditionalData,
  props,
  initialData = {},
  url,
}) {
  const data = reactive({
    name: "",
    number: "",
    start_date: null,
    unmaskedStartDate: "",
    end_date: null,
    unmaskedEndDate: "",
    project_id: "",
    funding_type: "",
    description: "",
    competence_project_role_id: "",
    competence_tf_id: "",
    competence_grade_id: "",
    competence_tf_requirement_id: "",
    competence_tf_duty_id: "",
    title: "",
    ...initialData,
  });
  const dataFromServer = reactive({});
  const cityOptions = ref([]);
  const employmentOptions = ref([]);
  const workTypeOptions = ref([]);
  const fundingOptions = ref([]);
  const projectOptions = ref([]);
  const projectRolesOptions = ref([]);
  const projectTfOptions = ref([]);
  const projectGradesOptions = ref([]);
  const dutiesOptions = ref([]);
  const requirementsOptions = ref([]);
  const metaData = ref({});
  const isDataLoading = ref(false);
  const statusRef = ref("");

  const getProjectData = async () => {
    try {
      const { data: responseData } = await request("/projects?archived=0", {
        method: "GET",
        auth: true,
      });

      projectOptions.value = responseData;
    } catch (e) {}
  };

  const getProjectRoles = async () => {
    try {
      const {
        competencies_project_roles,
        competencies_technical_fields,
        competencies_grades,
        tam_request_cities,
        tam_request_employment_forms,
        tam_request_work_types,
        tam_request_funding_types,
      } = await getEnums(
        [
          "competencies_project_roles",
          "competencies_technical_fields",
          "competencies_grades",
          "tam_request_cities",
          "tam_request_employment_forms",
          "tam_request_work_types",
          "tam_request_funding_types",
        ],
        true
      );

      projectRolesOptions.value = competencies_project_roles;
      projectTfOptions.value = competencies_technical_fields;
      projectGradesOptions.value = competencies_grades;
      cityOptions.value = tam_request_cities;
      employmentOptions.value = tam_request_employment_forms;
      workTypeOptions.value = tam_request_work_types;
      fundingOptions.value = tam_request_funding_types;
    } catch (e) {}
  };

  const getRequirementAndDutiesOptions = async (id) => {
    try {
      const currentOption = projectTfOptions.value.find((tf) => tf.id === id);
      if (
        currentOption &&
        currentOption.deleted_at &&
        metaData.value.deleted_entities
      ) {
        const duty = metaData.value.deleted_entities.tf_duty;
        dutiesOptions.value.push(duty);
        const requirement = metaData.value.deleted_entities.tf_requirement;
        requirementsOptions.value.push(requirement);
      } else {
        const { data: duties } = await request(
          `enums/get_tf_duties?competence_tf_id=${id}`,
          {
            auth: true,
          }
        );
        const { data: requirements } = await request(
          `enums/get_tf_requirements?competence_tf_id=${id}`,
          {
            auth: true,
          }
        );
        dutiesOptions.value = duties;
        requirementsOptions.value = requirements;
      }
    } catch (e) {}
  };

  const getRequestData = async (id) => {
    try {
      isDataLoading.value = true;
      const { data: responseData, meta } = await request(`${url}/${id}`, {
        auth: true,
      });

      metaData.value = meta;
      // props добавить
      data.name = responseData.name;
      data.number =
        props.mode === ModeEnums.TEMPLATE ? "" : responseData.number;
      data.start_date = new Date(responseData.start_date * 1000);
      data.end_date = new Date(responseData.end_date * 1000);
      data.project_id = responseData.project.id;
      if (responseData.project.archived) {
        projectOptions.value.push(meta.archived_entities.project);
      }
      data.funding_type = responseData.funding_type;
      data.description = responseData.description;
      data.competence_project_role_id = responseData.project_role.id;
      if (responseData.project_role.deleted_at) {
        projectRolesOptions.value.push(meta.deleted_entities.project_role);
      }
      data.competence_tf_id = responseData.technical_field.id;
      if (responseData.technical_field.deleted_at) {
        projectTfOptions.value.push(meta.deleted_entities.technical_field);
        dutiesOptions.value.push(meta.deleted_entities.tf_duty);
        requirementsOptions.value.push(meta.deleted_entities.tf_requirement);
      }
      data.competence_grade_id = responseData.grade.id;
      if (responseData.grade.deleted_at) {
        projectGradesOptions.value.push(meta.deleted_entities.grade);
      }

      data.competence_tf_requirement_id = responseData.tf_requirement.id;
      data.competence_tf_duty_id = responseData.tf_duty.id;
      data.title = responseData.title;
      setAdditionalData && setAdditionalData(responseData, data);
      Object.assign(dataFromServer, data);
      statusRef.value = responseData.status;
      isDataLoading.value = false;
    } catch (e) {
      isDataLoading.value = false;
    }
  };

  const title = computed(() => {
    if (props.mode === ModeEnums.EDIT) {
      return `Редактировать ${data.title}`;
    } else {
      return "Новый запрос";
    }
  });

  const dialogModalRef = ref();
  const dialogOptions = {
    dialogTitle: "Сбросить данные",
    dialogBody:
      "Вы уверены, что хотите закрыть окно создания нового запроса? При закрытии введенные данные не сохранятся.",
    dialogOnSuccessButton: "Продолжить",
    dialogOnCancelButton: "Отмена",
    dialogOnSuccess: () => {
      props.onClose(false, null);
    },
  };

  const compareValues = (keysArr) => {
    let isChanged = false;
    keysArr.forEach((key) => {
      if (data[key] !== dataFromServer[key]) {
        if (key.includes("unmasked")) {
          return;
        }
        isChanged = true;
      }
    });

    return isChanged;
  };

  const isChanged = computed(() => {
    if (!props.id) {
      return true;
    }

    return compareValues(Object.keys(data));
  });

  const onSuccess = () => {
    props.onClose(false, null);
  };

  const onCloseHandler = () => {
    if (props.mode !== ModeEnums.EDIT) {
      dialogModalRef.value.click();
    } else {
      props.onClose(false, null);
    }
  };

  const requestMode = ref(RequestTypeEnum.REQUEST);

  return {
    getProjectData,
    getProjectRoles,
    getRequestData,
    getRequirementAndDutiesOptions,
    cityOptions,
    employmentOptions,
    workTypeOptions,
    fundingOptions,
    projectOptions,
    projectRolesOptions,
    projectTfOptions,
    projectGradesOptions,
    dutiesOptions,
    requirementsOptions,
    data,
    title,
    dialogModalRef,
    dialogOptions,
    isChanged,
    onSuccess,
    onCloseHandler,
    requestMode,
    isDataLoading,
    statusRef,
  };
}
