<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <div class="account-logo">
            <router-link to="admin-users">
              <img src="@/assets/img/logo2.png" alt="Синкос" />
            </router-link>
          </div>

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Авторизоваться</h3>
              <p class="account-subtitle">Доступ к нашей панели управления</p>
              <core-form :initialValidation="schema" :onSuccess="onSuccess" url="/login" v-slot="{ errors, isLoading }">
                <div class="input-block mb-4">
                  <core-email-input name='email' label='Адрес электронной почты' :showLabel='true' :data='data'
                    :showError='true' :errors='errors' />
                </div>
                <div class="input-block mb-4">
                  <div class="row align-items-center">
                    <div class="col">
                      <label class="col-form-label">Пароль</label>
                    </div>
                    <div class="col-auto">
                      <router-link class="text-muted" to="forgot-password">
                        Забыли пароль?
                      </router-link>
                    </div>
                  </div>
                  <core-password-input required :showLabel='false' label='Пароль' name='password' type="password"
                    :data='data' :errors='errors' :showError='true' :mask='null' />
                </div>
                <div class="input-block mb-4 text-center">
                  <core-submit-button className="btn btn-primary account-btn" type="submit" :isLoading='isLoading'
                    text='Авторизоваться' />
                </div>
              </core-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { schema } from "@/shared/validation/login.ts";
import { router } from "@/router";
import { USER_ROLE_ENUM } from '@/shared/validation/enums.ts';
import { useUserStore } from '@/stores/user.ts';
import request from '@/shared/core-api/index.ts';
const user = useUserStore();
const onSuccess = async (values) => {
  const token = values.token;
  user.role = values.role;
  document.cookie = `token=${token}`;
  if (values.role === USER_ROLE_ENUM.ADMIN) {
    router.push("/admin-users");
  } else {
    const { data } = await request('/me', {
      auth: true
    });

    user.name = data.name;
    user.avatar = data.image_path;

    router.push("/dashboard");
  }

}

const data = reactive({
  email: '',
  password: '',
});

</script>
