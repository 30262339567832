import { router } from "@/router";

const unprotectedRoutes = ['reset-password', 'login', 'forgot-password', 'register']

export const redirectUnauthorizedUsers = () => {
  const routeName = router.currentRoute.value.name
  if (unprotectedRoutes.includes(routeName)) {
    return;
  }

  router.replace("/");
}