<template>
  <div>
    <Form @submit="onSubmit" :validation-schema="initialValidation" v-slot="{ errors, setErrors }" ref='formRef'>
      <slot v-bind="{ errors, isLoading, fieldsName, setErrors }"></slot>
      <core-error-notification v-if='isShowErrorNotification' :errors='errors' :fieldsName='fieldsName' />
      <div class="text-danger text-center" id="common">{{ errors.common }}</div>
    </Form>
  </div>
</template>
<script setup>
import { Form } from "vee-validate";
import { reactive, ref } from 'vue';
import request from '@/shared/core-api/index.ts';
const formRef = ref();
const props = defineProps({
  initialValidation: Object,
  url: String,
  onSuccess: Function,
  onError: Function,
  auth: Boolean,
  additionalData: Object,
  isShowErrorNotification: Boolean,
  formatBeforeSubmit: Function,
})

const isLoading = ref(false)
const fieldsName = reactive({

})

const onSubmit = async (values, { setErrors }) => {
  try {
    if (props.formatBeforeSubmit) {
      values = props.formatBeforeSubmit(values)
    }
    if (props.additionalData) {
      values = { ...values, ...props.additionalData }
    }

    isLoading.value = true;
    const response = await request(props.url, {
      method: 'POST',
      data: values,
      auth: props.auth,
      headers: {
        'Accept': 'application/json',
        'Content-Type': "multipart/form-data;"
      }
    })
    isLoading.value = false;
    props.onSuccess && props.onSuccess(response.data);
  } catch (e) {
    isLoading.value = false;
    const errors = e.data.errors;
    if (Array.isArray(errors)) {
      setErrors({ common: e.data.message });
    } else if (typeof errors === 'object') {
      setErrors({ ...e.data.errors });
    } else {
      setErrors({ common: e.data.message });
    }
    props.onError && props.onError(e, setErrors);
  }
}
</script>
