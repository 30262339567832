<template>
  <label v-if='showLabel' class="col-form-label">
    {{ label }}
    <span v-if='required' class="text-danger">*</span>
  </label>
  <Field :name="name" v-model='data[name]' :validateOnModelUpdate='false' :validateOnChange='false'
    :validateOnBlur='false'>
    <input type='numeric' :placeholder="placeholder" class="form-control"
      :class="{ 'is-invalid': showError && errors[name] }" v-maska :onMaska='phoneOnChange'
      data-maska="+7 (###) ### ## ##" v-model="data[maskedValueFieldName]" :disabled='disabled'>
  </Field>
  <div class="invalid-feedback">{{ showError && errors[name] }}</div>
  <div class="emailshow text-danger" :id="name"></div>
</template>
<script setup>
import { Field } from "vee-validate";
import { vMaska } from "maska";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount } from 'vue';
const props = defineProps({
  data: Object,
  disabled: Boolean,
  placeholder: String,
  showError: Boolean,
  errors: Object,
  name: String,
  maskedValueFieldName: String,
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  }
})

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

const phoneOnChange = ({ detail }) => {
  const value = detail.masked;
  props.data[props.name] = value.replace(/\D/g, "");
}

</script>