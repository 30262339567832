<template>
  <bid v-if='isShow' :mode='route.meta.mode' />
</template>
<script setup>
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const isShow = ref(true);
watch(
  () => route.meta.mode,
  () => {
    isShow.value = false;
    setTimeout(() => {
      isShow.value = true
    })
  },
)

</script>