<template>
  <div class="content container-fluid">
    <user-profile v-if='showUser' :onClose='showUserHandler' />
    <create-project-modal v-if='isShowProjectModal' :onClose='setProjectModal' />
    <requests-modal v-if='requestModal.isShow' :onClose='showRequestsModal' :initialFilters='requestModal.filters'
      :type='requestModal.type' />
    <breadcrumb-header />

    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="card-group dashboard__user-info-container mb-30">
          <div class="card dash-widget">
            <div class="card-body">
              <span class="dash-widget-icon bg-white">
                <img :src='user.avatar || photoPlaceholder' alt="image" class="img-fluid rounded-circle img-container">
              </span>
              <div>
                <h4>{{ user.name }}</h4>
                <div v-if='isResourseManagerRole'>{{ userInfo.company && userInfo.company.short_name }}</div>
                <span v-else class='badge bg-inverse-success'>{{ userInfo.role }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class='user-info-container__info'>
                <div>Мобильный номер телефона:</div>
                <div>{{ formatStringToPhone(userInfo.phone) }}</div>
                <div class="pro-edit user-info-container__edit">
                  <a class="edit-icon" @click='showUserHandler(true)'><i class="fas fa-pencil-alt"></i></a>
                </div>
              </div>
              <div class='user-info-container__info'>
                <div>Электронная почта:</div>
                <div>{{ userInfo.email }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class='row counter-card__container'>
        <counter-card text='Всего проектов' :counter='counters.projects_count' :img='briefcaseIcon' />
        <counter-card :text='isResourseManagerRole ? "Заявок на рассмотрении" : "Всего запросов компетенций"'
          :counter='isResourseManagerRole ? counters.applications_under_review : counters.requests_total_in_work'
          :img='megaphoneIcon' />
        <counter-card :text='isResourseManagerRole ? "Откликов на рассмотрении" : "Запросов на рассмотрении"'
          :counter='isResourseManagerRole ? counters.responses_under_review : counters.requests_under_review'
          :img='fileIcon' />
      </div>
    </div>

    <div class="col-12">
      <div class="showentries mb-3 dashboard__counter-header">
        <div v-if='!isResourseManagerRole' class="dashboard__tab-container">
          <h2 class="dashboard__tab-title">
            Проекты
          </h2>

          <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
            <li class="nav-item">
              <a v-if='user.isITManagerRole' class="nav-link active" href="#solid-rounded-justified-tab1"
                data-bs-toggle="tab" @click.prevent="setProjectModal(true)">+Создать проект</a>
            </li>
          </ul>

        </div>
        <ul v-else class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified dashboard__tab-container"
          :class="isTable && 'opacity-0'">
          <li class="nav-item">
            <a class="nav-link active" href="#solid-rounded-justified-tab1" data-bs-toggle="tab"
              @click.prevent="setIsApplications(false)">Отклики</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#solid-rounded-justified-tab2" data-bs-toggle="tab"
              @click="setIsApplications(true)">Заявки</a>
          </li>
        </ul>
        <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified  dashboard__tab-container">
          <li class="nav-item">
            <a class="nav-link active h-100 rounded-tabs" href="#solid-rounded-justified-tab1" data-bs-toggle="tab"
              @click="setTableMode(false)">Карточки</a>
          </li>
          <li class="nav-item rounded-tabs">
            <a class="nav-link  h-100 rounded-tabs" href="#solid-rounded-justified-tab2" data-bs-toggle="tab"
              @click="setTableMode(true)">Таблица</a>
          </li>
        </ul>
      </div>
    </div>

    <div class='w-100'>
      <core-table v-if='isShowTable' :headers='isTable ? headers : graphicHeader' :showHeader='isTable ? true : false'
        :formatServerData='!isTable && formatData' :url='tableUrl' ref='tableRef'
        :tableCellClassName='!isTable && "apexchart-table"'>
        <template #item-graphic="data">
          <div class='dashboard-project__apexchart-container'>
            <div v-for="project in data.projects" class="dashboard-project__apexchart-card">
              <apex-chart-card :id='project.number' :name='project.name' :project='project' />
            </div>
          </div>
        </template>
        <template #item-number="{ number, id }">
          <div @click='showRequestsModal(true, { project_id: id })' class='cursor-pointer'>
            {{ number }}
          </div>
        </template>
        <template #item-name="{ name, id }">
          <div @click='showRequestsModal(true, { project_id: id })' class='cursor-pointer'>
            {{ name }}
          </div>
        </template>
        <template #item-under_review_requests_it="{ counts, id }">
          <div @click='showRequestsModal(true, { status: "На рассмотрении", project_id: id })' class='cursor-pointer'>
            {{ counts && counts["На рассмотрении"] }}
          </div>
        </template>
        <template #item-agreed_requests_it="{ counts, id }">
          <div @click='showRequestsModal(true, { status: "Согласовано", project_id: id })' class='cursor-pointer'>
            {{ counts && counts["Согласовано"] }}
          </div>
        </template>
        <template #item-under_review_requests="{ counts, id }">
          <div class='cursor-pointer'
            @click='showRequestsModal(true, { status: "На рассмотрении", project_id: id }, EntitiesEnum.BID)'>
            {{ counts && counts.applications }}
          </div>
        </template>
        <template #item-agreed_requests="{ counts, id }">
          <div class='cursor-pointer'
            @click='showRequestsModal(true, { status: "На рассмотрении", project_id: id }, EntitiesEnum.RESOURCES)'>
            {{ counts && counts.responses }}
          </div>
        </template>
      </core-table>
    </div>
  </div>
</template>
<script setup>
import { useUserStore } from '@/stores/user.ts';
import { USER_ROLE_ENUM } from '@/shared/validation/enums.ts';
import { ref, onMounted, computed } from 'vue';
import request from '@/shared/core-api/index.ts';
import { formatStringToPhone } from '@/shared/utils/phone.ts';
import photoPlaceholder from '@/assets/img/profiles/user-avatar.png';
import briefcaseIcon from '@/assets/img/profiles/briefcase.svg';
import megaphoneIcon from '@/assets/img/profiles/megaphone.svg'
import fileIcon from '@/assets/img/profiles/file.svg'
import {
  requestsUrl,
  resourceApplicationsUrl,
  resourceTableUrl,
  resourceResponsesUrl,
  requestsHeaders,
  applicationHeaders,
  graphicHeader,
  meUrl,
  countersUrl,
} from './dashboard.options.ts';
import { EntitiesEnum } from '@/shared/enums/index.ts';

const user = useUserStore();
const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

const isTable = ref(false);
const setTableMode = (isShow) => {
  isTable.value = isShow;
  updateServerOptions();
}

const showUser = ref(false)
const showUserHandler = (isShow) => {
  showUser.value = isShow;

  if (!isShow) {
    getUserData();
  }
}

const isShowProjectModal = ref(false)
const setProjectModal = (isShow) => {
  if (!isShow) {
    isShowProjectModal.value = false;
    updateServerOptions();
    return;
  }

  isShowProjectModal.value = true;
}

const isApplications = ref(false);
const isShowTable = ref(true);
const setIsApplications = (isApplication) => {
  isShowTable.value = false;
  setTimeout(() => {
    isApplications.value = isApplication;
    isShowTable.value = true;
  });
}

const userInfo = ref({});
const counters = ref({});
const isResourseManagerRole = computed(() => {
  return user.role === USER_ROLE_ENUM.RESOURSE_MANAGER
});

const tableUrl = computed(() => {
  if (!user.isResourseManagerRole) {
    return requestsUrl;
  };

  if (user.isResourseManagerRole && isApplications.value && !isTable.value) {
    return resourceApplicationsUrl;
  }

  if (user.isResourseManagerRole && !isApplications.value && !isTable.value) {
    return resourceResponsesUrl;
  }

  if (user.isResourseManagerRole && isTable.value) {
    return resourceTableUrl;
  }
});

const headers = computed(() => {
  if (!user.isResourseManagerRole) {
    return requestsHeaders;
  };

  if (user.isResourseManagerRole && isApplications.value) {
    return applicationHeaders;
  }

  if (user.isResourseManagerRole && !isApplications.value) {
    return applicationHeaders;
  }
})

const getUserData = async () => {
  try {
    const { data } = await request(meUrl, {
      auth: true
    });

    user.avatar = data.image_path;
    user.name = data.name;
    userInfo.value = data;
  } catch (e) {

  }
}

const getCounters = async () => {
  try {
    const { data: countersData } = await request(countersUrl, {
      auth: true
    })

    counters.value = countersData;
  } catch (e) {

  }
}

onMounted(async () => {
  try {
    getUserData();
    getCounters();
  } catch (e) { }
});

const formatData = (data) => {
  const newData = [{
    projects: []
  }];
  data.forEach(item => {
    newData[0].projects.push(item)
  })
  return newData;
}

const requestModal = ref({
  isShow: false,
  filters: {},
  type: ''
});

const showRequestsModal = (isShow = false, filters = {}, type = '') => {
  requestModal.value.filters = filters;
  requestModal.value.isShow = isShow;
  requestModal.value.type = type;
  if (!isShow) {
    updateServerOptions()
  }
}

</script>
