<template>
  <div class="content container-fluid">
    <breadcrumb-header />

    <register-requests-filters :filters='filters' :resetFilters='resetFilters' />

    <core-table :headers='headers' url='/admin/register_requests' ref='tableRef' :initialPage='initialPage'
      :initialData='initialFiltersState'>
      <template #item-user_name="{ user_name }">
        <div class='d-flex align-items-center'>
          <div class="table-avatar table-avatar">
            <span class="user-img me-3"><img class='header-avatar' :src="photoPlaceholder" alt="User Image" /></span>
          </div>
          {{ user_name }}
        </div>
      </template>
      <template #item-created_at='{ created_at }'>
        <div>
          {{ fromTimestampToISOString(created_at && created_at * 1000) }}
        </div>
      </template>
      <template #item-status="{ status }">
        <div>
          <div class='badge'
            :class="status === 'Отклонено' ? 'bg-inverse-danger' : status === 'Новый' ? 'bg-inverse-info' : 'bg-inverse-success'">
            {{ status }}
          </div>
        </div>
      </template>
      <template #item-actions="{ id, blocked }">
        <div class="text-end">
          <div class="dropdown dropdown-action">
            <a href="javascript:;" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false"><i class="material-icons">more_vert</i></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" @click='setId(id, blocked)' href="javascript:;">
                <span class='action-icon-container'>
                  <i class="fa fa-external-link m-r-5"></i>
                </span>
                Открыть
              </a>
            </div>
          </div>
        </div>
      </template>
    </core-table>
  </div>

  <div v-if='userId.id'>
    <employer-request :id='userId.id' :onClose='setId'></employer-request>
  </div>
</template>
<script setup>
import { fromTimestampToISOString } from '@/shared/utils/date.ts'
import { ref, reactive } from 'vue';
import photoPlaceholder from '@/assets/img/profiles/user-avatar.png';
import { headers } from './register-requests.options.ts';
import { useRegisterRequestsFilters } from '@/widgets/filters/index.ts';

const tableRef = ref();
const updateServerOptions = (data) => {
  tableRef.value.updateServerOptions(data)
}

const {
  filters,
  resetFilters,
  initialFiltersState,
  initialPage
} = useRegisterRequestsFilters(updateServerOptions)

const userId = reactive({
  id: null
})
const setId = (id) => {
  userId.id = id

  if (!id) {
    tableRef.value.updateServerOptions()
  }
}

</script>
