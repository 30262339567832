export const primaryAllowedExts = [
  "DOC",
  "DOCX",
  "JPEG",
  "PNG",
  "XLS",
  "CSV",
  "PPT",
  "TXT",
  "RTF",
  "PDF",
  "TIFF",
];
