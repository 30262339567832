import * as Yup from "yup";
import { FieldsValidation } from "@/shared/validation/index.ts";

export const schema = Yup.object().shape({
  name: FieldsValidation.requiredString,
  number: FieldsValidation.agreementNumber,
  start_date: FieldsValidation.notEmptyString,
  end_date: FieldsValidation.notEmptyString,
  city: FieldsValidation.notEmptyString,
  employment_form: FieldsValidation.notEmptyString,
  funding_type: FieldsValidation.notEmptyString,
  work_type: FieldsValidation.notEmptyString,
  project_id: FieldsValidation.notEmptyString,
  description: FieldsValidation.notEmptyString,
  competence_project_role_id: FieldsValidation.notEmptyString,
  competence_tf_id: FieldsValidation.notEmptyString,
  competence_grade_id: FieldsValidation.notEmptyString,
  workers_count: FieldsValidation.notEmptyString,
  competence_tf_requirement_id: FieldsValidation.notEmptyString,
  competence_tf_duty_id: FieldsValidation.notEmptyString,
  work_hours_start: FieldsValidation.notEmptyString,
  work_hours_end: FieldsValidation.notEmptyString,
});
