<template>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='onLocalFileClose' />
  <core-table :url='url' :headers='headers' ref='tableRef' :minHeight='0'>
    <template #item-status="{ status }">
      <div :class='getStatusClass(status)' class='p-2 text-center'>
        {{ status }}
      </div>
    </template>
    <template #item-pay_rate="{ pay_rate }">
      {{ pay_rate }} руб
    </template>
    <template #item-cost="{ cost }">
      {{ cost }} руб
    </template>
    <template #item-created_at='{ created_at }'>
      <div>
        {{ fromTimestampToISOString(created_at && created_at * 1000) }}
      </div>
    </template>
    <template #item-file="{ files }">
      <div v-if="files && files[0]" @click='openFile(files[0])'
        class='w-100 d-flex justify-content-center cursor-pointer'>
        <file-icon className='row-file-icon' :fileName='files[0].name' />
      </div>
    </template>
    <template #item-actions="{ id, status }">
      <div class="buttons_vertical_container p-2">
        <core-submit-button spinnerOptions='text-light spinner-border-sm'
          className='margin-2 width-25 btn btn-success me-1 btn-sm' text='Принять'
          :disabled='!getStatusPermission(status, [StatusesEnums.NEW, StatusesEnums.CONSIDIRATION])'
          :isLoading='isLoading' @click='changeStatus(id, StatusesEnums.AGREED)' />
        <core-submit-button spinnerOptions='text-light spinner-border-sm'
          :disabled='!getStatusPermission(status, [StatusesEnums.NEW, StatusesEnums.CONSIDIRATION])'
          :isLoading='isLoading' className='btn btn-danger me-1 btn-sm width-25' text='Отклонить'
          @click='changeStatus(id, StatusesEnums.DENIED)' />
      </div>
    </template>
  </core-table>
</template>
<script setup>
import { ref } from 'vue';
import { getStatusClass } from '@/pages/requests/index.ts';
import { fromTimestampToISOString } from '@/shared/utils/date.ts';
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
import { StatusesEnums, getStatusPermission } from '@/pages/requests/index.ts';
import request from '@/shared/core-api/index.ts';
const props = defineProps({
  url: String,
  headers: Array
})
const { selectedFile, openFile, closeFile } = useFileModal();
const tableRef = ref();
const isLoading = ref(false);

const onLocalFileClose = () => {
  closeFile();
  tableRef.value.updateServerOptions();
}

const changeStatus = async (response_id, status) => {
  try {

    isLoading.value = true;
    let url = '';
    if (status === StatusesEnums.AGREED) {
      url = `${props.url}/accept`;
    } else if (status === StatusesEnums.DENIED) {
      url = `${props.url}/decline`;
    }

    const queryParamsBody = {
      response_id,
    }

    await request(url, {
      method: 'POST',
      auth: true,
      queryParamsBody
    })
    isLoading.value = false;
    tableRef.value.updateServerOptions();
  } catch (e) {
    isLoading.value = false;
  }
}

defineExpose({
  meta: tableRef,
})

</script>