export const COMPANY_TYPE_ENUM = {
  IP: "Индивидуальный Предприниматель (ИП)",
};

export const USER_ROLE_ENUM = {
  RESOURSE_MANAGER: "Ресурсный менеджер",
  PURCHASING_MANAGER: "Менеджер закупок",
  IT_MANAGER: "ИТ менеджер",
  ADMIN: "Администратор",
};
