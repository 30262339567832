<template>
  <file-view-modal v-if='selectedFile' :file='selectedFile' :onClose='closeFile' />
  <div class="offcanvas offcanvas-end core-canvas__card show" tabindex="-1" aria-labelledby="edit_employeeLabel">
    <modal-header :title='name' :isShowSeparator='true' :onClose='() => onClose(null, false)' />
    <div class="offcanvas-body">
      <div class="card-body">
        <div class="pdf-file mt-2 d-flex" v-for='(file) in files'>
          <file :file='file' :onClick='openFile' />
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-backdrop fade show" @click='onClose(null, false)'></div>
</template>
<script setup>
import { useFileModal } from '@/widgets/file-view-modal/index.ts';
const { selectedFile, openFile, closeFile } = useFileModal();
const props = defineProps({
  name: String,
  files: Array,
  onClose: Function,
});
</script>