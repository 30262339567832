export const resourcesFPHeaders = [
  {
    text: "ID запроса",
    value: "fp_request_number",
    sortable: true,
    custom: false,
  },
  {
    text: "Название",
    value: "fp_request_name",
    sortable: true,
    custom: false,
  },
  { text: "Статус", value: "status", sortable: true, custom: true },
  {
    text: "Дата создания",
    value: "created_at",
    sortable: true,
    custom: true,
  },
  { text: "Стоимость", value: "cost", sortable: true, custom: true },
  { text: "Действие", value: "actions", sortable: false, custom: true },
];

export const tableOptions = {
  requests: {
    url: "/fp_requests",
    headers: [
      { text: "ID запроса", value: "number", sortable: true, custom: false },
      { text: "Название", value: "name", sortable: true, custom: false },
      { text: "Статус", value: "status", sortable: true, custom: true },
      { text: "Проект", value: "project_name", sortable: true, custom: true },
      {
        text: "Дата создания",
        value: "created_at",
        sortable: true,
        custom: true,
      },
      {
        text: "Отклики",
        value: "responses_count",
        sortable: true,
        custom: false,
      },
      {
        text: "Срок предоставления КП",
        value: "kp_date",
        sortable: true,
        custom: true,
      },
      { text: "Действие", value: "actions", sortable: false, custom: true },
    ],
  },
  resources: {
    url: "/fp_resources",
    headers: resourcesFPHeaders,
  },
};
