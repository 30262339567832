import request from "./index.ts";

export const getEnums = async (keys, auth) => {
  const url =
    keys && keys.length > 0 ? `/enums/all?keys=${keys}` : "/enums/all";
  try {
    const response = await request(url, {
      headers: {
        Accept: "application/json",
      },
      auth,
    });

    return response.data;
  } catch (e) {}
};
