<template>
  <div class="offcanvas offcanvas-end competencies-container show" tabindex="-1">
    <modal-header title='Техническая область' :isShowSeparator='true' :onClose='onCloseHandler' />
    <div class="offcanvas-body competencies-modal__body">
      <div class="card-body">
        <filter-component :updateServerOptions='getData'></filter-component>

        <core-form url='/me/technical_fields' :auth='true' :additionalData="additionalData" :onSuccess='onSuccess'
          v-slot='{ isLoading }'>
          <Field name='competence_tf_ids' v-model="result">
            <p v-if='technicalFields.length' class='text-primary cursor-pointer competencies__filter-button'
              @click='selectAll'>
              {{ isSelectedAll ? 'Сбросить' : 'Выбрать все' }}
            </p>
            <perfect-scrollbar>
              <div class='competencies-modal__tf-container'>
                <div class='competencies-modal__list'>
                  <div v-for="item in technicalFields" :key="item.id" class='checkbox_container'>
                    <label>{{ item.name }} </label>
                    <input type="checkbox" class="form-check-input" :checked='selectState[item.id]'
                      @change='(e) => onChange(e, item.id)' />
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
            <div class='buttons_container'>
              <core-submit-button type='button'
                :onClick='(e, submit) => submitHandler(submit, disabled || isDataLoading)' :changeBeforeSubmit='true'
                className="btn btn-primary submit-btn" text='Сохранить' :isLoading='isLoading' />
            </div>
          </Field>

        </core-form>
      </div>
    </div>
    <!-- end offcanvas-body-->
  </div>
  <div class="offcanvas-backdrop fade show" @mousedown='onCloseHandler'></div>
</template>
<script setup>
import FilterComponent from '@/components/Filters/Search';
import request from '@/shared/core-api/index.ts';
import { Field } from "vee-validate";
import { reactive, onMounted, ref, watch, computed, onUnmounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
const $toast = useToast();

const props = defineProps({
  onClose: Function,
});

const additionalData = {
  _method: 'PUT'
}
const technicalFields = ref([]);
const initialState = ref([]);
const url = '/enums/get_technical_fields';

const isDataLoading = ref(false)

const selectState = reactive({});
const result = ref('');

const disabled = computed(() => {
  if (result.value === '') {
    return true;
  } else {
    return false;
  }
});

const toastInstanceRef = ref(null);

const createWarning = () => {
  if (toastInstanceRef.value) {
    return;
  }
  toastInstanceRef.value = $toast.open({
    type: 'warning',
    message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">
              Вы не можете продолжить работу. Выберите Техническую область
            </p>
          </div>
        `,
    duration: 0,
    dismissible: false,
    position: 'bottom-left'
  });
}

// Если нет ни одной начальной компетенции - то запретить пользователю закрывать модальное окно.
const onCloseHandler = () => {
  if (initialState.value.length < 1) {
    createWarning();
    return
  }

  props.onClose(false);
}

watch(
  selectState,
  (value) => {
    formatDataForRequest(value)
  },
  { deep: true }
);

const getData = async (text) => {
  try {
    let currentUrl = text && text.search_query ? `${url}?search_query=${text.search_query}` : url;
    let { data, meta } = await request(currentUrl, {
      auth: true,
    });

    if (meta.deleted_entities && meta.deleted_entities.technical_fields) {
      data = data.concat(meta.deleted_entities.technical_fields)
    }

    technicalFields.value = data;
  } catch (e) {

  }
}

const getInitialData = async () => {
  try {
    const { data } = await request('/me/technical_fields', {
      auth: true
    });


    initialState.value = data;
    data.forEach((field) => {
      selectState[field.technical_field.id] = 1;
    })

  } catch (e) {

  }
}

const onChange = (event, id) => {
  const checked = event.target.checked;
  selectState[id] = checked ? 1 : 0
}

onMounted(async () => {
  isDataLoading.value = true;
  await getInitialData();
  await getData();
  isDataLoading.value = false;
})

onUnmounted(() => {
  if (toastInstanceRef.value) {
    toastInstanceRef.value.dismiss()
  }
})

const formatDataForRequest = (state) => {
  let arr = [];
  for (const [key, value] of Object.entries(state)) {
    if (value) {
      arr.push(key)
    }
  }

  result.value = arr.join(',');
}

const onSuccess = (value) => {
  $toast.open({
    type: 'success',
    message: `
          <div class='error-core-container'>
            <p class="py-0 my-0 w-100">
              Отлично! Теперь перечень запросов будет соответствовать выбранной Технической области!
            </p>
          </div>
        `,
    duration: 2000,
    position: 'bottom-left'
  })
  props.onClose(false);
}

const submitHandler = (submit, isNotAllowed) => {
  if (toastInstanceRef.value) {
    $toast.clear();
    toastInstanceRef.value = null;
  }
  if (isNotAllowed) {
    createWarning();
  } else {
    submit();
  }
}

const isSelectedAll = computed(() => {
  let isNotSelected = true;
  technicalFields.value.forEach(item => {
    if (selectState[item.id] !== 1) {
      isNotSelected = false
      return;
    }
  });

  return isNotSelected
});

const selectAll = () => {
  const isSelected = isSelectedAll.value;
  technicalFields.value.forEach(item => {
    selectState[item.id] = isSelected ? 0 : 1;
  })
}
</script>
