<template>
  <div>
    <label v-if='showLabel' class="col-form-label">
      {{ label }}
      <span v-if='required' class="text-danger">*</span>
    </label>
    <Field :standalone='isIgnore' :name="name" class="form-control" v-model='data[name]' :validateOnModelUpdate='false'
      :validateOnChange='false' :validateOnBlur='false'>
      <input class="form-control" :class="{ 'is-invalid': showError && errors[name] }" v-maska:[stringOptions]
        :placeholder='placeholder' :data-maska="mask" v-model="data[name]" :disabled='disabled' />
    </Field>
    <div class="invalid-feedback">{{ showError && errors[name] }}</div>
    <div class="emailshow text-danger" :id="name"></div>
  </div>
</template>
<script setup>
import { Field } from "vee-validate";
import { vMaska } from "maska";
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount } from 'vue';
const props = defineProps({
  data: Object,
  disabled: Boolean,
  stringOptions: Object,
  placeholder: String,
  showError: Boolean,
  errors: Object,
  name: String,
  mask: String,
  fieldsName: Object,
  label: String,
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isIgnore: {
    type: Boolean, default: false
  }
})

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})
</script>