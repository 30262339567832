import { router } from "@/router";

export const setParams = (obj) => {
  const params = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value || value === 0) {
      params[key] = value;
    }
  }

  router.replace({ path: router.path, query: params });
};

export const addParams = (obj, initialParams = {}) => {
  const params = {
    ...initialParams,
  };
  for (const [key, value] of Object.entries(obj)) {
    if (value || value === 0) {
      params[key] = value;
    }
  }

  router.replace({ path: router.path, query: params });
};
