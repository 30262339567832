<template>
  <label v-if='showLabel' class="col-form-label">
    {{ label }}
    <span v-if='required' class="text-danger">*</span>
  </label>
  <Field :name='name' v-model='data[unmaskedValueFieldName]'>
    <VueDatePicker v-model="data[name]" locale='ru' @date-update='onChange' :hideInputIcon='hideIcon' :clearable='false'
      :enable-time-picker='false' ref="datepicker" :format='inputFormat' :placeholder="placeholder" :disabled='disabled'
      :time-picker='isTimePicker'>
      <template #action-row="{ selectDate }">
        <div class='w-100 d-flex justify-content-center mb-2'>
          <b-button variant="outline-secondary me-1" v-if='isTimePicker' @click='selectDate'
            type='button'>Выбрать</b-button>
        </div>
      </template>
    </VueDatePicker>
  </Field>
  <div class="invalid-feedback"></div>
  <div class="emailshow text-danger" :id="name">{{ showError && errors[name] }}</div>
</template>
<script setup>
import { watch } from 'vue';
import { Field } from "vee-validate";
import { createISOString } from '@/shared/utils/date.ts';
import { addFieldName, removeFieldName } from '@/shared/core-form/utils/addFieldsName.ts';
import { onMounted, onBeforeMount } from 'vue';
import { ref } from 'vue';

const datepicker = ref(null);

const props = defineProps({
  name: String,
  data: Object,
  showError: Boolean,
  errors: Object,
  unmaskedValueFieldName: String,
  inputFormat: String,
  placeholder: {
    type: String, default: 'Выберите дату'
  },
  fieldsName: Object,
  label: String,
  disabled: {
    type: Boolean, default: false
  },
  showLabel: {
    type: Boolean, default: false
  },
  required: {
    type: Boolean, default: false
  },
  isTimePicker: {
    type: Boolean, default: false
  },
  hideIcon: {
    type: Boolean, default: false
  }
})

onMounted(() => {
  if (props.fieldsName && props.label && props.name) {
    addFieldName(props.fieldsName, props.name, props.label)
  }
})

onBeforeMount(() => {
  if (props.fieldsName && props.label && props.name) {
    removeFieldName(props.fieldsName, props.name, props.label)
  }
})

const onChange = (newDate) => {
  props.data[props.name] = newDate;
  datepicker.value.closeMenu()
}

watch(
  () => props.data[props.name],
  (value) => {
    formatDate(value)
  },
  { deep: true }
);


const formatDate = (value) => {
  if (props.isTimePicker) {
    let { hours, minutes } = value;
    if (typeof hours === 'number' && hours < 10) {
      hours = `0${hours}`
    }

    if (typeof minutes === 'number' && minutes < 10) {
      minutes = `0${minutes}`
    }

    props.data[props.unmaskedValueFieldName] = `${hours}:${minutes}`
  } else {
    const isoString = createISOString(value)
    props.data[props.unmaskedValueFieldName] = isoString;
  }
}

</script>
<style>
.dp__input {
  height: 44px;
  border-radius: 0.375rem;
}

.dp__theme_light {
  --dp-hover-color: #e3e3e3;
  --dp-border-color-hover: #e3e3e3;
  --dp-disabled-color: #e9ecef
}
</style>